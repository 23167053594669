import React from 'react'
import { Navigate } from 'react-router-dom'

//Dashboard
import Dashboard from '../pages/DashboardAnalytics'

import Basic404 from '../pages/AuthenticationInner/Errors/Basic404'

//login
import Login from '../pages/Authentication/Login'
import Logout from '../pages/Authentication/Logout'
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword'
import ResetPasswordPage from '../pages/Authentication/PasswReset'
import LockScreen from '../pages/Authentication/LockScreen'

//Settings
import {
  DefaultCourseSettings,
  InstructorManagment,
  RolePermissions,
} from '../pages/Settings'
// User Profile
import UserProfile from '../pages/Authentication/UserProfile'

//Tutorial
import Tutorials from '../pages/Support/Tutorials'
import Categories from '../pages/Support/Categories'

//User Management
import UserListingPage from '../pages/UserManagment/UserListing'
import UserProfilePage from '../pages/UserManagment/UserProfile'
import AdminListingPage from '../pages/UserManagment/AdminListing'
import ImportLogs from '../pages/UserManagment/ImportLogs'

//Facility Management
import FacilityListingPage from '../pages/FacilityManagement/FacilitiesList'
import FacilityPage from '../pages/FacilityManagement/FacilityPage'

//Calendar
import CalendarPage from '../pages/Calendar'

//Courses
import ScheduledListing from '../pages/Courses/ScheduledListing'
import ScheduledCategories from '../pages/Courses/ScheduledCategories'
import OnlineCategories from '../pages/Courses/OnlineCategories'
import OnlineTags from '../pages/Courses/OnlineTags'
import GeneralStep from '../pages/Courses/OnlineCourses/Manage/GeneralStep'
import DigitalCourse from '../pages/Courses/OnlineCourses/Manage/DigitalCourse'
import LiveCourse from '../pages/Courses/OnlineCourses/Manage/LiveCourse'
import CompetencyTest from '../pages/Courses/OnlineCourses/Manage/CompetencyTest'
import KnowledgeCheck from '../pages/Courses/OnlineCourses/Manage/KnowledgeCheck'

import Default from '../pages/Courses/OnlineCourses/Manage/Default'

import OnlineCourses from '../pages/Courses/OnlineCourses'

import EditScheduledCategory from '../pages/Courses/ScheduledCategories/EditScheduledCategory'

import ManageEvent from '../pages/Courses/ManageEvent'
import EventDetails from '../pages/Courses/EventDetails'
import CompanyNews from '../pages/Settings/CompanyNews'
import MediaLibrary from '../pages/Courses/MediaLibrary'
import ManageCompanyNews from '../pages/Settings/CompanyNews/ManageCompanyNews'
import PushNotifications from '../pages/Settings/PushNotifications'
import ManageSurvey from '../pages/Settings/Surveys/ManageSurvey'
import Surveys from '../pages/Settings/Surveys'
import ViewSurvey from '../pages/Settings/Surveys/ViewSurvey'
import {
  CalendarPermissions,
  CommunicationPermissions,
  CompanyManagementPermissions,
  CoursesPermissions,
  FacilityManagementPermissions,
  ReportingPermissions,
  SettingsPermissions,
  SupportPermissions,
  UserManagementPermissions,
} from '../sharedTypes'
import GeneralCoursePackage from '../pages/Courses/Packages/GeneralCoursePackage'
import ManagePackageCourses from '../pages/Courses/Packages/ManageCoursePackage'
import FacilitatorView from '../pages/Calendar/FacilitatorView'
import CourseHistory from '../pages/Reporting/CourseHistory'
import CourseHistorySummary from '../pages/Reporting/CourseHistory/Summary'
import UserReport from '../pages/Reporting/UserReport'
import DownloadRedirect from '../pages/Other/DownloadRedirect'
import CourseReport from '../pages/Reporting/CourseReport'
import CourseReportItem from '../pages/Reporting/CourseReport/Details/CourseReportItem'
import CourseTranslations from '../pages/Courses/OnlineCourses/Translations'
import Register from '../pages/Authentication/Register'
import Code from '../pages/Authentication/Code'
import MasteredDashboard from '../pages/DashboardAnalytics/Mastered'
import CompanyManagement from '../pages/CompanyManagement'
import ManageCompany from '../pages/CompanyManagement/Manage'
import CompanyReport from '../pages/Reporting/CompanyReport'
import UserCreationLog from '../pages/UserManagment/UserCreationLog'
import UsersSearch from '../pages/UserManagment/UsersSearch'
import FTags from '../pages/Settings/ftags'
import NewUsersReport from '../pages/Reporting/NewUsersReport'
import MatchUserAccount from '../pages/UserManagment/UserCreationLog/MatchUser'

const authProtectedRoutes = [
  {
    path: '/',
    component: (isMastered: boolean) =>
      isMastered ? <MasteredDashboard /> : <Dashboard />,
  },
  { path: '/profile', component: <UserProfile /> },
  //settings
  {
    path: '/role-permissions',
    component: <RolePermissions />,
    permission: SettingsPermissions.VIEW_ROLES_AND_PERMISSIONS,
  },
  {
    path: '/instructor-management',
    component: <InstructorManagment />,
    permission: SettingsPermissions.VIEW_INSTRUCTOR_LISTING,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/ftags',
    component: <FTags />,
    permission: SettingsPermissions.VIEW_FTAG_LISTING,
  },
  {
    path: '/course-settings',
    component: <DefaultCourseSettings />,
    permission: SettingsPermissions.VIEW_DEFAULT_COURSE_SETTINGS,
  },

  //support
  {
    path: '/support/tutorials',
    component: <Tutorials />,
    permission: SupportPermissions.VIEW_TUTORIALS_LISTING,
    render: (isMastered: boolean) => isMastered,
  },
  {
    path: '/support/categories',
    component: <Categories />,
    permission: SupportPermissions.VIEW_SUPPORT_CATEGORIES,
    render: (isMastered: boolean) => isMastered,
  },

  //user management
  {
    path: '/user-listing',
    component: <UserListingPage />,
    permission: UserManagementPermissions.VIEW_USER_LISTING,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/user-creation-log',
    component: <UserCreationLog />,
    permission: UserManagementPermissions.VIEW_USER_CREATION_LOG,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/match-user-account',
    component: <MatchUserAccount />,
    permission: UserManagementPermissions.MATCH_USER_ACCOUNT,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/users-search',
    component: <UsersSearch />,
    permission: UserManagementPermissions.USERS_SEARCH,
    render: (isMastered: boolean) => isMastered,
  },
  {
    path: '/user-listing/profile/:userId',
    component: <UserProfilePage />,
    permission: UserManagementPermissions.VIEW_USER_PROFILE,
  },
  {
    path: '/admin-listing',
    component: <AdminListingPage />,
    permission: UserManagementPermissions.VIEW_ADMIN_LISTING,
  },
  {
    path: '/import-log',
    component: <ImportLogs />,
    permission: UserManagementPermissions.VIEW_IMPORT_LOG,
    render: (isMastered: boolean) => !isMastered,
  },
  //facility management
  {
    path: '/facility-management',
    component: <FacilityListingPage />,
    permission: FacilityManagementPermissions.VIEW_FACILITY_MANAGEMENT,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/facility-management/facility/:facilityId',
    component: <FacilityPage />,
    permission: FacilityManagementPermissions.VIEW_FACILITY_DETAILS,
    render: (isMastered: boolean) => !isMastered,
  },
  //company management
  {
    path: '/company-management',
    component: <CompanyManagement />,
    render: (isMastered: boolean) => isMastered,
  },
  {
    path: '/company-manage',
    component: <ManageCompany />,
    permission: CompanyManagementPermissions.ADD_COMPANY,
    render: (isMastered: boolean) => isMastered,
  },
  {
    path: '/company-manage/:id',
    component: <ManageCompany />,
    permission: CompanyManagementPermissions.EDIT_COMPANY,
    render: (isMastered: boolean) => isMastered,
  },
  //calendar
  {
    path: '/calendar',
    component: <CalendarPage />,
    permission: CalendarPermissions.VIEW_CALENDAR,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/facilitator-view/:scheduleId',
    component: <FacilitatorView />,
    permission: CalendarPermissions.VIEW_FACILITATOR_COURSE_VIEW,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/calendar/manage/:eventId',
    component: <ManageEvent fromCalendar={true} />,
    render: (isMastered: boolean) => !isMastered,
  },
  //courses
  { path: '/courses/scheduled-categories', component: <ScheduledCategories /> },
  {
    path: '/courses/scheduled-categories/:id',
    component: <EditScheduledCategory />,
  },
  { path: '/courses/scheduled-courses', component: <ScheduledListing /> },
  {
    path: '/courses/scheduled-courses/manage/:eventId',
    component: <ManageEvent />,
  },
  {
    path: '/courses/scheduled-courses/:eventId',
    component: <EventDetails />,
  },
  { path: '/courses', component: <OnlineCourses /> },
  { path: '/courses/online-categories', component: <OnlineCategories /> },
  { path: '/courses/keywords', component: <OnlineTags /> },
  {
    path: '/courses/packages/general',
    component: <GeneralCoursePackage />,
    permission: CoursesPermissions.ADD_CLONE_PACKAGE,
  },
  {
    path: '/courses/packages/manage/:packageId',
    component: <ManagePackageCourses />,
  },
  {
    path: '/courses/translations/:courseId',
    component: <CourseTranslations />,
    permission: CoursesPermissions.VIEW_TRANSLATIONS,
  },
  {
    path: '/courses/history/summary',
    component: <CourseHistorySummary />,
    permission: ReportingPermissions.VIEW_COURSE_HISTORY_SUMMARY_REPORT,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/user/report',
    component: <UserReport />,
    permission: ReportingPermissions.VIEW_USER_REPORT,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/new-users/report',
    component: <NewUsersReport />,
    permission: ReportingPermissions.VIEW_USER_REPORT,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/courses/report',
    component: <CourseReport />,
    permission: ReportingPermissions.VIEW_COURSE_REPORT,
    render: (isMastered: boolean) => isMastered,
  },
  {
    path: '/courses/report/:reportId',
    component: <CourseReportItem />,
    permission: ReportingPermissions.VIEW_COURSE_REPORT,
    render: (isMastered: boolean) => isMastered,
  },
  {
    path: '/company/report',
    component: <CompanyReport />,
    render: (isMastered: boolean) => isMastered,
  },
  {
    path: '/courses/history',
    component: <CourseHistory />,
    permission: ReportingPermissions.VIEW_COURSE_HISTORY_REPORT,
    render: (isMastered: boolean) => !isMastered,
  },
  {
    path: '/company-news',
    component: <CompanyNews />,
    permission: CommunicationPermissions.VIEW_COMPANY_NEWS_LISTING,
  },
  {
    path: '/push-notifications',
    component: <PushNotifications />,
    permission: CommunicationPermissions.VIEW_NOTIFICATION_LISTING,
  },
  {
    path: '/courses/media-library',
    component: <MediaLibrary />,
    permission: CoursesPermissions.VIEW_MEDIA_LIBRARY,
  },
  {
    path: '/company-news/manage',
    component: <ManageCompanyNews />,
    permission: CommunicationPermissions.ADD_COMPANY_NEWS,
  },
  {
    path: '/company-news/manage/:id',
    component: <ManageCompanyNews />,
    permission: CommunicationPermissions.VIEW_COMPANY_NEWS,
  },
  {
    path: '/surveys',
    component: <Surveys />,
    permission: CommunicationPermissions.VIEW_SURVEY_LISTING,
  },
  {
    path: '/surveys/manage',
    component: <ManageSurvey />,
    permission: CommunicationPermissions.CREATE_CLONE_SURVEY,
  },
  {
    path: '/surveys/manage/:id',
    component: <ManageSurvey />,
    permission: CommunicationPermissions.EDIT_SURVEY,
  },
  {
    path: '/surveys/view/:id?',
    component: <ViewSurvey />,
    permission: CommunicationPermissions.VIEW_SURVEYS,
  },
]

const publicRoutes = [
  // Authentication Page
  { path: '/logout', component: <Logout />, forceLogin: false },
  { path: '/login', component: <Login /> },
  { path: '/register', component: <Register /> },
  { path: '/code', component: <Code /> },
  { path: '/forgot-password', component: <ForgetPasswordPage /> },
  { path: '/reset-password/:token', component: <ResetPasswordPage /> },
  {
    path: '/create-password/:token',
    component: <ResetPasswordPage isNew={true} />,
  },
  { path: '/lock', component: <LockScreen /> },
  { path: '/download', component: <DownloadRedirect />, forceLogin: false },

  { path: '/404', component: <Basic404 />, forceLogin: false },

  { path: '*', component: <Navigate to='/404' />, forceLogin: false },
]

const courseStepsRoutes = [
  {
    path: '/courses/general/:courseId',
    component: <GeneralStep />,
    permission: CoursesPermissions.ADD_EDIT_COURSE,
  },
  {
    path: '/courses/digital/:courseId',
    component: <DigitalCourse />,
    permission: CoursesPermissions.ADD_EDIT_COURSE,
  },
  {
    path: '/courses/live/:courseId',
    component: <LiveCourse />,
    permission: CoursesPermissions.ADD_EDIT_COURSE,
  },
  {
    path: '/courses/competency/:courseId',
    component: <CompetencyTest />,
    permission: CoursesPermissions.ADD_EDIT_COURSE,
  },
  {
    path: '/courses/knowledge/:courseId',
    component: <KnowledgeCheck />,
  },
  {
    path: '/courses/default',
    component: <Default />,
    permission: CoursesPermissions.ADD_EDIT_COURSE,
  },
]

export { authProtectedRoutes, publicRoutes, courseStepsRoutes }
