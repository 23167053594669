import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Button, Col, FormFeedback, Input, Row } from 'reactstrap'
import {
  CREDENTIALS_TYPE,
  FilterOption,
  GetDepartmentsDTO,
  GetPositionsDTO,
  TDepartment,
  TPosition,
} from '../../../sharedTypes'
import Flatpickr from 'react-flatpickr'
import {
  checkUserCredential,
  getDepartments,
  getFacilities,
  getPositions,
} from '../../../helpers/api_helper'
import { Option, Step1Props } from './types'
import { AsyncSelectWithSearch } from '../../Common/SelectWithSearch'
import _ from 'lodash'
import { handleError } from '../../../helpers/toast_helper'
import DateOfBirthInput from '../../Common/DateOfBirthInput'

const UserDetails = ({
  handleBlur,
  handleChange,
  setFieldValue,
  setFieldError,
  setFieldTouched,
  values,
  touched,
  isValid,
  errors,
  onCancel,
  onNext,
}: Step1Props) => {
  const [departments, setDepartments] = useState<TDepartment[]>([])
  const [departmentOptions, setDepartmentOptions] = useState<FilterOption[]>([])
  const [positions, setPositions] = useState<TPosition[]>([])
  const [positionOptions, setPositionOptions] = useState<FilterOption[]>([])
  const datePickerRef = useRef<Flatpickr>(null)

  useEffect(() => {
    setDepartmentOptions(
      departments
        .filter(department => department.facilityId === values.facility?.value)
        .map(department => ({
          value: department.id,
          label: department.name,
        })),
    )

    setPositionOptions(
      positions
        .filter(position => position.facilityId === values.facility?.value)
        .map(position => ({
          value: position.id,
          label: position.name,
        })),
    )
  }, [values.facility, positions, departments])

  const _onNext = () => {
    const emailCheck = values.email
      ? checkUserCredential({
          credentialType: CREDENTIALS_TYPE.EMAIL,
          email: values.email,
        })
          .then(res => {
            if (res.data) {
              setFieldError(
                'user.email',
                'This email already matches a current user in the system.',
              )
              return false
            }
            return true
          })
          .catch(err => {
            handleError(err)
            return false
          })
      : Promise.resolve(true)

    const phoneCheck = values.phone
      ? checkUserCredential({
          credentialType: CREDENTIALS_TYPE.PHONE,
          phone: values.phone,
        })
          .then(res => {
            if (res.data) {
              setFieldError(
                'user.phone',
                'This phone number already matches a current user in the system.',
              )
              return false
            }
            return true
          })
          .catch(err => {
            handleError(err)
            return false
          })
      : Promise.resolve(true)

    Promise.all([emailCheck, phoneCheck]).then(results => {
      if (results.every(result => result)) {
        onNext()
      }
    })
  }

  const fetchFacilities = useCallback(
    (inputValue?: string) => {
      return getFacilities({ key: inputValue })
        .then(res => {
          return res.data.facilities.map(facility => {
            return {
              label: facility.name,
              value: facility.id,
            }
          })
        })
        .catch(() => [])
    },
    [values.facility],
  )

  const fetchDepartments = useCallback(
    (inputValue?: string) => {
      const query: GetDepartmentsDTO.Request = {
        key: inputValue,
      }
      if (values.facility) {
        query.facilityIds = [values.facility?.value as number]
      }
      return getDepartments(query)
        .then(res => {
          const grouped: Option[] = _.map(
            _.groupBy(res.data.departments, 'name'),
            (group: TDepartment[], key) => {
              return {
                label: key,
                value: group[0].id,
              }
            },
          )
          return grouped
        })
        .catch(() => [])
    },
    [values.facility],
  )

  const fetchPositions = useCallback(
    (inputValue?: string) => {
      const query: GetPositionsDTO.Request = {
        key: inputValue,
      }
      if (values.facility) {
        query.facilityIds = [values.facility?.value as number]
      }
      return getPositions(query).then(res => {
        const grouped: Option[] = _.map(
          _.groupBy(res.data.positions, 'name'),
          (group: TPosition[], key) => {
            return {
              label: key,
              value: group[0].id,
            }
          },
        )
        return grouped
      })
    },
    [values.facility],
  )

  return (
    <div className='my-3 mt-0 mx-0 pt-2 add-user-details'>
      <div className='vstack gap-3'>
        <Row>
          <Col>
            <label htmlFor='firstName' className='form-label'>
              First Name*
            </label>
            <Input
              name='user.firstName'
              className='form-control'
              id='firstName'
              placeholder='Enter name'
              type='text'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.firstName}
              invalid={!!(touched.firstName && errors.firstName)}
            />
            {touched.firstName && errors.firstName ? (
              <FormFeedback type='invalid'>{errors.firstName}</FormFeedback>
            ) : null}
          </Col>
          <Col>
            <label htmlFor='lastName' className='form-label'>
              Last Name*
            </label>
            <Input
              name='user.lastName'
              className='form-control'
              id='lastName'
              placeholder='Enter last name'
              type='text'
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.lastName}
              invalid={!!(touched.lastName && errors.lastName)}
            />
            {touched.lastName && errors.lastName ? (
              <FormFeedback type='invalid'>{errors.lastName}</FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor='dob' className='form-label'>
              Date of Birth*
            </label>
            <DateOfBirthInput
              handleBlur={handleBlur}
              value={values.dob}
              name='user.dob'
              className='form-control'
              id='birthday'
              placeholder='mm/dd/yyyy'
              onBlur={handleBlur}
              handleChange={value => {
                setFieldValue('user.dob', value)
              }}
              invalid={!!(touched.dob && errors.dob)}
            />
            {touched.dob && errors.dob ? (
              <FormFeedback type='invalid' className='d-block'>
                {errors.dob}
              </FormFeedback>
            ) : null}
          </Col>
          <Col>
            <label htmlFor='facility' className='form-label'>
              Facility*
            </label>
            <AsyncSelectWithSearch
              name='user.facility'
              id='facilities'
              onChange={option => {
                setFieldValue('user.facility', option)
                setFieldValue('user.department', undefined)
                setFieldValue('user.position', undefined)
              }}
              onBlur={() => {
                setFieldTouched('user.facility')
              }}
              value={values.facility}
              isMulti={false}
              isClearable={false}
              isSearchable={true}
              placeholder={'Select facility'}
              defaultOptions
              loadOptions={fetchFacilities}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
              }}
              className={`${
                touched.facility && errors.facility ? ' is-invalid' : ''
              }`}
              menuPortalTarget={document.body}
            />

            {touched.facility && errors.facility ? (
              <FormFeedback type='invalid' className='d-block'>
                {errors.facility}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <label htmlFor='department' className='form-label'>
              Department*
            </label>
            <AsyncSelectWithSearch
              name='user.department'
              id='department'
              onChange={option => {
                setFieldValue('user.department', option)
              }}
              onBlur={() => {
                setFieldTouched('user.department')
              }}
              value={values.department}
              isMulti={false}
              isClearable={false}
              isSearchable={true}
              placeholder={'Select department'}
              defaultOptions
              loadOptions={fetchDepartments}
              key={JSON.stringify(values.facility)}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
              }}
              className={`${
                touched.department && errors.department ? ' is-invalid' : ''
              }`}
              menuPortalTarget={document.body}
            />
            {touched.department && errors.department ? (
              <FormFeedback type='invalid' className='d-block'>
                {errors.department}
              </FormFeedback>
            ) : null}
          </Col>
          <Col>
            <label htmlFor='position' className='form-label'>
              Position*
            </label>
            <AsyncSelectWithSearch
              name='user.position'
              id='positions'
              onChange={option => {
                setFieldValue('user.position', option)
              }}
              onBlur={() => {
                setFieldTouched('user.position')
              }}
              value={values.position}
              isMulti={false}
              isClearable={false}
              isSearchable={true}
              placeholder={'Select position'}
              defaultOptions
              loadOptions={fetchPositions}
              key={JSON.stringify(values.facility)}
              styles={{
                menuPortal: base => ({ ...base, zIndex: 9999 }),
              }}
              className={`${
                touched.position && errors.facility ? ' is-invalid' : ''
              }`}
              menuPortalTarget={document.body}
            />
            {touched.position && errors.position ? (
              <FormFeedback type='invalid' className='d-block'>
                {errors.position}
              </FormFeedback>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col>
            <label htmlFor='phone' className='form-label'>
              Phone Number*
            </label>
            <Input
              name='user.phone'
              className='form-control'
              id='phone'
              placeholder='_ _ _ - _ _ _ - _ _ _ _'
              type='text'
              onChange={e => {
                handleChange(e)
              }}
              onBlur={handleBlur}
              invalid={!!(touched.phone && errors.phone)}
              value={values.phone}
            />
            {touched.phone && errors.phone ? (
              <FormFeedback type='invalid'>{errors.phone}</FormFeedback>
            ) : null}
          </Col>
          <Col>
            <label htmlFor='email' className='form-label'>
              Email address*
            </label>
            <Input
              name='user.email'
              className='form-control'
              id='name'
              placeholder='user@email.com'
              type='text'
              onChange={e => {
                handleChange(e)
              }}
              onBlur={handleBlur}
              value={values.email}
              invalid={!!(touched.email && errors.email)}
            />
            {touched.email && errors.email ? (
              <FormFeedback type='invalid'>{errors.email}</FormFeedback>
            ) : null}
          </Col>
        </Row>
      </div>
      <div className='mt-2'>
        <div className='hstack gap-2 justify-content-between'>
          <Button
            className='btn-soft-primary align-middle'
            color='secondary'
            onClick={onCancel}
          >
            Back
          </Button>
          <Button
            className='align-middle'
            color='primary'
            disabled={!isValid}
            onClick={_onNext}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

export default UserDetails
