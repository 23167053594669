export enum USER_STATUSES {
  'ACTIVE' = 'active',
  'INACTIVE' = 'inactive',
}

export enum USER_VERIFICATION_STATUS {
  VERIFIED = 'verified',
  PENDING_MATCHING = 'pending matching',
  NO_MATCH = 'no match',
  EXPIRED = 'expired',
}

export enum USER_REGISTERED_STATUSES {
  'Yes' = 'yes',
  'No' = 'no',
}

export const USER_GENDERS = <const>['male', 'female', 'other']
export type TUserGender = (typeof USER_GENDERS)[number]

export type TUserReportData = {
  userId: number
  coursesNotStarted: number
  coursesInProgress: number
  coursesCompletedInPast12Months: number
  coursesOverdue: number
}

export const USER_EMPLOYEE_TYPES = <const>['full-time', 'part-time']
export type TUserEmployeeType = (typeof USER_EMPLOYEE_TYPES)[number]

export enum USER_COURSE_STATUSES {
  NO_COURSES = 'no courses',
  ON_SCHEDULE = 'on schedule',
  PENDING_COMPLETION = 'pending completion',
  COMPLETE = 'complete',
}

export enum USER_CREATION_METHOD {
  HOSTED = 'hosted',
  MANUAL = 'manual',
}

export type TGrouped = {
  name: string
  values: number[]
}

export enum COURSE_TYPES {
  'ONBOARDING' = 'Onboarding',
  'ANNUAL_EDUCATION' = 'Annual Education',
  'ENHANCED_LEARNING' = 'Enhanced Learning',
  // 'MICRO_LEARNING' = 'Micro Learning',
  // 'COMPLIANCE' = 'Compliance',
  // 'CONTINUING_EDUCATION' = 'Continuing Education',
  // 'FTAG' = 'Ftag',
}

export const CourseTypesOptions = Object.keys(COURSE_TYPES).map(key => ({
  value: COURSE_TYPES[key as keyof typeof COURSE_TYPES],
  label: COURSE_TYPES[key as keyof typeof COURSE_TYPES],
}))

export enum UserManagementPermissions {
  VIEW_USER_MANAGEMENT = 'userManagement.viewUserManagement',
  VIEW_USER_LISTING = 'userManagement.viewUserListing',
  VIEW_USER_CREATION_LOG = 'userManagement.viewUserCreationLog',
  ADD_NEW_USER = 'userManagement.addNewUser',
  EDIT_USER = 'userManagement.editUser',
  MATCH_USER_ACCOUNT = 'userManagement.matchUserAccount',
  VIEW_USER_PROFILE = 'userManagement.viewUserProfile',
  VIEW_USER_INFO = 'userManagement.viewUserInfo',
  VIEW_USER_COURSE_HISTORY = 'userManagement.viewUserCourseHistory',
  ASSIGN_COURSE_TO_USER = 'userManagement.assignCourseToUser',
  REQUEST_EDIT_OF_USER_INFO = 'userManagement.requestEditOfUserInfo',
  DEACTIVATE_USER = 'userManagement.deactivateUser',
  EDIT_ASSIGNMENT = 'userManagement.editAssignment',
  REMOVE_ASSIGNMENT = 'userManagement.removeAssignment',
  COURSE_OVERRIDE = 'userManagement.courseOverride',
  VIEW_COURSE_ASSIGNMENT_BY_POSITION = 'userManagement.viewCourseAssignmentByPosition',
  VIEW_IMPORT_LOG = 'userManagement.viewImportLog',
  VIEW_ADMIN_LISTING = 'userManagement.viewAdminListing',
  USERS_SEARCH = 'userManagement.usersSearch',
  ADD_NEW_ADMIN = 'userManagement.addNewAdmin',
  EDIT_ADMIN = 'userManagement.editAdmin',
  DEACTIVATE_ADMIN = 'userManagement.deactivateAdmin',
  GRAND_ADMIN_PORTAL_ACCESS = 'userManagement.grandAdminPortalAccess',
  REMOVE_ADMIN_PORTAL_ACCESS = 'userManagement.removeAdminPortalAccess',
  PRINT_TRANSCRIPT = 'userManagement.printTranscript',
  VIEW_CERTIFICATE = 'userManagement.viewCertificate',
}

export enum CoursesPermissions {
  VIEW_COURSE_LISTING = 'courses.viewCourseListing',
  CLONE_COURSE = 'courses.cloneCourse',
  ADD_COURSE_REVISION = 'courses.addCourseRevision',
  ASSIGN_COURSE = 'courses.assignCourse',
  ADD_EDIT_COURSE = 'courses.addEditCourse',
  ADD_COURSE_SCHEDULE = 'courses.addCourseSchedule',
  EDIT_COURSE_SCHEDULE = 'courses.editCourseSchedule',
  DELETE_COURSE_SCHEDULE = 'courses.deleteCourseSchedule',
  DELETE_COURSE = 'courses.deleteCourse',
  PUBLISH_COURSE = 'courses.publishCourse',
  ADD_CLONE_PACKAGE = 'courses.addClonePackage',
  EDIT_PACKAGE = 'courses.editPackage',
  ADD_COURSES_TO_PACKAGE = 'courses.addCoursesToPackage',
  REMOVE_COURSES_FROM_PACKAGE = 'courses.removeCoursesFromPackage',
  ADD_PACKAGE_SCHEDULE = 'courses.addScheduleForPackage',
  EDIT_PACKAGE_SCHEDULE = 'courses.editScheduleForPackage',
  DELETE_PACKAGE_SCHEDULE = 'courses.deleteScheduleForPackage',
  PUBLISH_PACKAGE = 'courses.publishPackage',
  DELETE_PACKAGE = 'courses.deletePackage',
  VIEW_MEDIA_LIBRARY = 'courses.viewMediaLibrary',
  VIEW_MEDIA = 'courses.viewMedia',
  ADD_MEDIA = 'courses.addMedia',
  EDIT_MEDIA = 'courses.editMedia',
  DELETE_MEDIA = 'courses.deleteMedia',
  VIEW_TRANSLATIONS = 'courses.viewTranslations',
  EDIT_SPANISH_TRANSLATION = 'courses.editSpanishTranslation',
  EDIT_CREOLE_TRANSLATION = 'courses.editCreoleTranslation',
  VIEW_COURSE_KEYWORDS = 'courses.viewCourseKeywords',
  ADD_COURSE_KEYWORDS = 'courses.addCourseKeywords',
  EDIT_COURSE_KEYWORDS = 'courses.editCourseKeywords',
  DELETE_COURSE_KEYWORDS = 'courses.deleteCourseKeywords',
}

export enum SupportPermissions {
  VIEW_TUTORIALS_LISTING = 'support.viewTutorialsListing',
  ADD_TUTORIAL = 'support.addTutorial',
  EDIT_TUTORIAL = 'support.editTutorial',
  DELETE_TUTORIAL = 'support.deleteTutorial',
  VIEW_FAQ_LISTING = 'support.viewFAQListing',
  VIEW_SUPPORT_CATEGORIES = 'support.viewSupportCategories',
  ADD_SUPPORT_CATEGORIES = 'support.addSupportCategories',
  EDIT_SUPPORT_CATEGORIES = 'support.editSupportCategories',
  DELETE_SUPPORT_CATEGORIES = 'support.deleteSupportCategories',
}

export enum SettingsPermissions {
  VIEW_DEFAULT_COURSE_SETTINGS = 'settings.viewDefaultCourseSettings',
  EDIT_DEFAULT_COURSE_SETTINGS = 'settings.editDefaultCourseSettings',
  VIEW_INSTRUCTOR_LISTING = 'settings.viewInstructorListing',
  ADD_INSTRUCTOR = 'settings.addInstructor',
  EDIT_INSTRUCTOR = 'settings.editInstructor',
  DELETE_INSTRUCTOR = 'settings.deleteInstructor',
  VIEW_ROLES_AND_PERMISSIONS = 'settings.viewRolesAndPermissions',
  ADD_CLONE_ROLE = 'settings.addCloneRole',
  EDIT_ROLE_NAME = 'settings.editRoleName',
  EDIT_ROLE_PERMISSIONS = 'settings.editRolePermissions',
  DELETE_ROLE = 'settings.deleteRole',
  ASSIGN_ROLE_TO_USERS = 'settings.assignRoleToUsers',
  VIEW_FTAG_LISTING = 'settings.viewFTagListing',
  ADD_FTAG = 'settings.addFTag',
  EDIT_FTAG = 'settings.editFTag',
  ADD_COURSE_FTAG = 'settings.addCourseFTag',
  REMOVE_COURSE_FTAG = 'settings.removeCourseFTag',
  DELETE_FTAG = 'settings.deleteFTag',
}

export enum CommunicationPermissions {
  VIEW_SURVEY_LISTING = 'communication.viewSurveyListing',
  VIEW_SURVEYS = 'communication.viewSurveys',
  CREATE_CLONE_SURVEY = 'communication.createCloneSurvey',
  EDIT_SURVEY = 'communication.editSurvey',
  SEND_SCHEDULE_SURVEY = 'communication.sendScheduleSurvey',
  VIEW_INDIVIDUAL_SURVEY_RESPONSES = 'communication.viewIndividualSurveyResponses',
  VIEW_SURVEY_RESPONSE_OVERVIEW = 'communication.viewSurveyResponseOverview',
  DELETE_SURVEY = 'communication.deleteSurvey',
  VIEW_NOTIFICATION_LISTING = 'communication.viewNotificationListing',
  VIEW_NOTIFICATIONS = 'communication.viewNotifications',
  CREATE_CLONE_NOTIFICATIONS = 'communication.createCloneNotifications',
  EDIT_NOTIFICATIONS = 'communication.editNotifications',
  SEND_SCHEDULE_NOTIFICATIONS = 'communication.sendScheduleNotifications',
  DELETE_NOTIFICATIONS = 'communication.deleteNotifications',
  VIEW_COMPANY_NEWS_LISTING = 'communication.viewCompanyNewsListing',
  VIEW_COMPANY_NEWS = 'communication.viewCompanyNews',
  ADD_COMPANY_NEWS = 'communication.addCompanyNews',
  EDIT_COMPANY_NEWS = 'communication.editCompanyNews',
  PUBLISH_COMPANY_NEWS = 'communication.publishCompanyNews',
  DELETE_COMPANY_NEWS = 'communication.deleteCompanyNews',
  SEND_SCHEDULE_COMPANY_NEWS = 'communication.sendScheduleCompanyNews',
}

export enum FacilityManagementPermissions {
  VIEW_FACILITY_MANAGEMENT = 'facilityManagement.viewFacilityManagement',
  VIEW_FACILITY_DETAILS = 'facilityManagement.viewFacilityDetails',
  VIEW_FACILITY_EMPLOYEES = 'facilityManagement.viewFacilityEmployees',
  ADD_NEW_FACILITY = 'facilityManagement.addNewFacility',
  EDIT_FACILITY = 'facilityManagement.editFacility',
  ACTIVATE_FACILITY = 'facilityManagement.activateFacility',
  DEACTIVATE_FACILITY = 'facilityManagement.deactivateFacility',
  ADD_NEW_GROUP = 'facilityManagement.addNewGroup',
  EDIT_GROUP = 'facilityManagement.editGroup',
  DELETE_GROUP = 'facilityManagement.deleteGroup',
}

export enum CalendarPermissions {
  VIEW_CALENDAR = 'calendar.viewCalendar',
  VIEW_FACILITATOR_COURSE_VIEW = 'calendar.viewFacilitatorCourseView',
  ADD_PARTICIPANTS = 'calendar.addParticipants',
  REMOVE_PARTICIPANTS = 'calendar.removeParticipants',
  CHECK_PARTICIPANT_IN = 'calendar.checkParticipantIn',
  PREVIEW_TEST = 'calendar.previewTest',
  RELEASE_TEST = 'calendar.releaseTest',
  OVERRIDE_TEST_SCORE = 'calendar.overrideTestScore',
}

export enum ReportingPermissions {
  VIEW_REPORTS = 'reporting.viewReports',
  VIEW_COURSE_HISTORY_REPORT = 'reporting.viewCourseHistoryReport',
  VIEW_NEW_USERS_REPORT = 'reporting.viewNewUsersReport',
  VIEW_USER_REPORT = 'reporting.viewUserReport',
  VIEW_COMPANY_REPORT = 'reporting.viewCompanyReport',
  VIEW_COURSE_REPORT = 'reporting.viewCourseReport',
  VIEW_COURSE_FEEDBACK_REPORT = 'reporting.viewCourseFeedbackReport',
  VIEW_COURSE_HISTORY_SUMMARY_REPORT = 'reporting.viewCourseHistorySummaryReport',
}

export enum CompanyManagementPermissions {
  VIEW_COMPANY_MANAGEMENT = 'companyManagement.viewCompanyManagement',
  ADD_COMPANY = 'companyManagement.addCompany',
  EDIT_COMPANY = 'companyManagement.editCompany',
  SWITCH_TO_VIEW_COMPANY_PORTAL = 'companyManagement.switchToViewCompanyPortal',
}
