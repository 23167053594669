import React, { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap'
import BreadCrumb from '../../../../Components/Common/BreadCrumb'

import {
  MATCH_USER_ACCOUNT_ACTION,
  TempUserData,
  TUser,
} from '../../../../sharedTypes'
import { getHostedData, matchUserAccount } from '../../../../helpers/api/users'
import UserCardList from './UserAccountDataCard'
import {
  handleError,
  successToastOptions,
} from '../../../../helpers/toast_helper'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ConfirmationModal from '../../../../Components/Modals/DeleteConfirmation'

const MatchUserAccount = () => {
  document.title = 'Match User Accounts | Mastered - Admin & Dashboard'

  const location = useLocation()
  const navigate = useNavigate()

  const [masteredAccount, setMasteredAccount] = useState<TUser | null>(
    location.state?.user || null,
  )
  const [hostedAccount, setHostedAccount] = useState<TempUserData | null>(null)
  const [isLoading, setIsLoading] = useState(true)
  const [confirmMatchAction, setConfirmMatchAction] =
    useState<MATCH_USER_ACCOUNT_ACTION | null>(null)

  useEffect(() => {
    if (masteredAccount) {
      getHostedData(masteredAccount.id)
        .then(response => {
          setHostedAccount(response.data)
        })
        .catch(handleError)
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [masteredAccount])

  const manageUserAccount = (action: MATCH_USER_ACCOUNT_ACTION) => {
    if (hostedAccount) {
      matchUserAccount({ action }, hostedAccount.id)
        .then(() => {
          toast(
            `The match is ${
              action === MATCH_USER_ACCOUNT_ACTION.APPROVE
                ? 'approved'
                : 'rejected '
            } successfully`,
            successToastOptions,
          )
          navigate('/user-creation-log')
        })
        .catch(handleError)
    }
  }

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='Match User Accounts'
            items={[
              {
                linkTo: '/',
                title: 'User Management',
              },
              {
                active: true,
                title: 'Match User Accounts',
              },
            ]}
          />
          <Row sm='12'>
            <Col>
              <Card>
                <CardHeader>
                  <div className='d-flex justify-content-between align-items-center gap-3'>
                    <h4 className='d-flex fs-20'>Mastered Account Data</h4>
                  </div>
                </CardHeader>
                <CardBody>
                  {masteredAccount && !isLoading ? (
                    <UserCardList
                      mastered={true}
                      user={masteredAccount}
                      matchedUser={
                        hostedAccount
                          ? {
                              ...hostedAccount,
                              departments: hostedAccount.department
                                ? [hostedAccount.department]
                                : undefined,
                            }
                          : null
                      }
                    />
                  ) : (
                    <Spinner />
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card style={{ border: '1px solid #0039c7' }}>
                <CardHeader>
                  <div className='d-flex justify-content-between align-items-center gap-3'>
                    <h4 className='d-flex fs-20 text-primary'>
                      Hosted Account Data
                    </h4>
                    <div>
                      <span className='badge bg-primary text-white fs-14'>
                        *This data will be saved
                      </span>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  {hostedAccount && !isLoading ? (
                    <UserCardList
                      user={{
                        ...hostedAccount,
                        departments: hostedAccount.department
                          ? [hostedAccount.department]
                          : undefined,
                      }}
                      matchedUser={masteredAccount}
                    />
                  ) : (
                    <Spinner />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Col
            sm='12'
            className='settings-navigation-container w-100'
            style={{ margin: 0 }}
          >
            <div className='d-flex justify-content-between px-2'>
              <Link to='/user-creation-log'>
                <Button color='light' className='btn'>
                  Cancel
                </Button>
              </Link>
              <div className='hstack gap-3 px-2'>
                <Button
                  color='soft-danger'
                  className='btn'
                  disabled={isLoading}
                  onClick={() => {
                    setConfirmMatchAction(MATCH_USER_ACCOUNT_ACTION.REJECT)
                  }}
                >
                  Reject Match
                </Button>
                <Button
                  color='primary'
                  className='btn'
                  disabled={isLoading}
                  onClick={() => {
                    setConfirmMatchAction(MATCH_USER_ACCOUNT_ACTION.APPROVE)
                  }}
                >
                  Approve Match
                </Button>
              </div>
            </div>
          </Col>
          <ConfirmationModal
            isOpen={!!confirmMatchAction}
            onClose={() => {
              setConfirmMatchAction(null)
            }}
            title='Warning'
            confirmIcon='ri-error-warning-line'
            confirmLabel='Yes'
            confirmBtnType='primary'
            message={`Are you sure you want to ${confirmMatchAction} this match?`}
            onDelete={() =>
              manageUserAccount(confirmMatchAction as MATCH_USER_ACCOUNT_ACTION)
            }
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default MatchUserAccount
