import React, {
  useEffect,
  useState,
  useRef,
  ChangeEvent,
  useCallback,
  useContext,
  useMemo,
} from 'react'
import { Col, Row, Button, CardBody } from 'reactstrap'
import BooksAndStethoscope from '../../../../assets/images/svg/Books_and_stethoscope.svg'
import { postDigitalCourseFile } from '../../../../helpers/api_helper'
import {
  OnlineCourseItem,
  OnlineCourseFormatsEnum,
} from '../../../../sharedTypes'
import withRouter, {
  WithRouterProps,
} from '../../../../Components/Common/withRouter'
import { handleError } from '../../../../helpers/toast_helper'
import { useFormik } from 'formik'
import { digitalCourseSchema } from '../../../../schemas'
import { CourseLayout } from '../../../../Components/Course'
import { toast } from 'react-toastify'
import { successToastOptions } from '../../../../helpers/toast_helper'
import { CourseContext } from '../../../../context/CourseContext'
import { useShouldGoTo } from '../../../../hooks/course/useShouldGoTo'
import Dropzone from 'react-dropzone'
import Lessons from '../Lessons/index'
import {
  createLesson,
  deleteLesson,
  getLessons,
  updateLessonsOrder,
  uploadLessonVideo,
} from '../../../../helpers/api/lessons'
import _ from 'lodash'
import { getMediaDetails } from '../../../../helpers/common'
import formatBytes from '../../../../utils/formatBytes'
import DeleteConfirmation from '../../../../Components/Modals/DeleteConfirmation'
import { ILesson } from '../Lessons/types'

export interface IForm {
  completeDays: number
  quizAttempts: number
}

const storylineCourseSteps = [
  'Create your course in Storyline 360',
  'Publish your Storyline Project',
  'When the Publish window appears select the LMS/LRS tab on the left',
  'Select Zip which will create a zipped version of your course files in the same location where your course was published',
  'Download the zip file',
  'Upload the zip file on this page',
]

const ReplaceFile = ({
  name,
  size,
  disabled,
  handleClick,
}: {
  name: string
  size: number
  disabled: boolean
  handleClick: () => void
}) => {
  return (
    <div>
      <section
        style={{
          maxWidth: 166,
          border: '#F0F4F9',
          borderWidth: 1,
          borderStyle: 'solid',
        }}
        className='mx-auto'
      >
        <section
          style={{ background: '#F0F4F9', height: 166 }}
          className='d-flex justify-content-center align-items-center'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='70'
            height='70'
            viewBox='0 0 70 70'
            fill='none'
          >
            <g id='icon/zip'>
              <path
                id='Vector'
                d='M58.3333 64.1654H11.6667C10.8931 64.1654 10.1513 63.8581 9.60427 63.3111C9.05729 62.7641 8.75 62.0222 8.75 61.2487V8.7487C8.75 7.97515 9.05729 7.23328 9.60427 6.6863C10.1513 6.13932 10.8931 5.83203 11.6667 5.83203H58.3333C59.1069 5.83203 59.8488 6.13932 60.3957 6.6863C60.9427 7.23328 61.25 7.97515 61.25 8.7487V61.2487C61.25 62.0222 60.9427 62.7641 60.3957 63.3111C59.8488 63.8581 59.1069 64.1654 58.3333 64.1654ZM55.4167 58.332V11.6654H14.5833V58.332H55.4167ZM40.8333 34.9987V49.582H29.1667V40.832H35V34.9987H40.8333ZM35 11.6654H40.8333V17.4987H35V11.6654ZM29.1667 17.4987H35V23.332H29.1667V17.4987ZM35 23.332H40.8333V29.1654H35V23.332ZM29.1667 29.1654H35V34.9987H29.1667V29.1654Z'
                fill='#85A8FF'
              />
            </g>
          </svg>
        </section>
        <section className='m-2'>
          <p>{name}</p>
          <p>{size} KB </p>
        </section>
      </section>
      <Button
        color={'primary'}
        disabled={disabled}
        className='btn btn-primary mt-3 align-middle mx-auto d-block'
        onClick={() => handleClick()}
      >
        <i className='ri-add-line me-1 fs-16'></i>Replace File
      </Button>
    </div>
  )
}

const DigitalCourse = ({ router }: WithRouterProps) => {
  const {
    course,
    setCourse,
    setCourseId,
    onNavigate,
    setLoading,
    loading,
    onRemoveFormat,
    onBack,
    goNext,
    deleteFormatModal,
    tabs,
    setDeleteFormatModal,
    isPublishing,
    isPublished,
    setUploadedCourseLessonsData,
    uploadedCourseLessonsData,
  } = useContext(CourseContext)
  const { setShouldGoTo, onSuccess } = useShouldGoTo({ goNext, onBack })
  const [initialValues, setInitialValues] = useState<null | IForm>(null)
  const hiddenFileInput = useRef<any>(null)

  const [lessons, setLessons] = useState<ILesson[]>([])
  const [lessonToDelete, setLessonToDelete] = useState<ILesson | null>(null)

  const onSubmit = useCallback(async () => {
    try {
      onSuccess()
    } catch (e) {
      handleError(e)
    }
  }, [router.params, onSuccess])

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues: {
      completeDays: 0,
      quizAttempts: 0,
    },
    validationSchema: digitalCourseSchema,
    onSubmit,
  })

  const allowToDeleteDigitalSettings = useMemo(
    () =>
      !course?.originalCourse ||
      !course.originalCourse?.formats.includes(OnlineCourseFormatsEnum.DIGITAL),
    [course],
  )

  const hasUnsavedData = useMemo(
    () => JSON.stringify(form.values) !== JSON.stringify(initialValues),
    [initialValues, form.values],
  )

  const handleClick = useCallback(() => {
    hiddenFileInput?.current?.click()
  }, [hiddenFileInput])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event?.target?.files) {
      const fileUploaded = event?.target?.files[0]
      onFileUpload(fileUploaded)
    }
  }

  const handleUploadLessons = async (files: File[]) => {
    if (files.length && course) {
      setLoading(true)
      const lessonsData = await Promise.all(
        files.map(async (file, index) => {
          const formData = new FormData()
          formData.append('file', file)

          try {
            const lesson = await createLesson(course.id)
            return {
              file: Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
              }),
              isOpen: index === 0,
              progress: 0,
              title: '',
              order: null,
              knowledgeChecks: [],
              id: lesson.data.id,
            }
          } catch (error) {
            handleError(error)
            return null
          }
        }),
      )

      const successfulLessonsData = lessonsData.filter(
        lesson => lesson !== null,
      )

      if (successfulLessonsData.length) {
        setLessons(prevLessons => [
          ...prevLessons,
          ...(successfulLessonsData as ILesson[]),
        ])

        const lessonFilesData = successfulLessonsData.map(lesson => ({
          id: lesson?.id as number,
          fileId: null,
        }))

        setUploadedCourseLessonsData(lessonFilesData)
        setLoading(false)
      }

      Promise.all(
        successfulLessonsData.map(async lesson => {
          if (!lesson) {
            return
          }

          const formData = new FormData()
          formData.append('file', lesson.file)

          uploadLessonVideo(lesson.id, formData).catch(handleError)
        }),
      )
    }
  }

  const disableActions = useMemo(
    () =>
      uploadedCourseLessonsData.length
        ? uploadedCourseLessonsData.some(data => data.fileId === null)
        : false,
    [uploadedCourseLessonsData],
  )

  const onFileUpload = useCallback(
    async (file: File) => {
      const form = new FormData()
      setLoading(true)

      form.append('file', file)
      await postDigitalCourseFile(Number(router.params.courseId), form)
        .then(digital => {
          setCourse((c: any) => ({ ...(c as OnlineCourseItem), digital }))
        })
        .catch(handleError)
        .finally(() => {
          setLoading(false)
        })
    },
    [router.params.courseId],
  )

  const fetchLessons = () => {
    if (course?.id) {
      getLessons({ courseId: course.id })
        .then(data => {
          const lessonsData = data.lessons.map((lesson, i) => ({
            title: _.get(lesson, 'translations[0].content.title', ''),
            fileId: lesson.fileId,
            file: getMediaDetails(lesson.file),
            isOpen: i === 0,
            id: lesson.id,
            order: lesson.order,
            knowledgeChecks: lesson.knowledgeChecks,
          }))

          setLessons(lessonsData)
        })
        .catch(handleError)
    }
  }

  useEffect(() => {
    setInitialFormValues()
    if (course) {
      fetchLessons()
    }
  }, [course])

  const setInitialFormValues = useCallback(() => {
    if (course?.digital) {
      const values: IForm = {
        completeDays: course?.digital?.completeDays,
        quizAttempts: course?.digital?.quizAttempts,
      }
      form.setValues(values, true)
      setInitialValues(values)
    }
  }, [course])

  useEffect(() => {
    setCourseId(Number(router.params.courseId))
  }, [router.params.courseId])

  const onReOrder = useCallback(
    (result: any) => {
      if (!result.destination || !course?.id) {
        return
      }

      const lessonsData = [...lessons]

      const [reorderedItem] = lessonsData.splice(result.source.index, 1)

      lessonsData.splice(result.destination.index, 0, reorderedItem)

      lessonsData.forEach((lesson, index: number) => {
        lesson.order = index + 1
      })

      const updatedOrder = lessonsData.map(({ id, order }) => ({
        lessonId: id as number,
        order,
      }))

      updateLessonsOrder({
        data: updatedOrder,
      })
        .then(() => {
          fetchLessons()
        })
        .catch(handleError)
    },
    [lessons],
  )

  const handleDelete = useCallback(async () => {
    try {
      if (lessonToDelete?.id && course?.id) {
        await deleteLesson(lessonToDelete.id)
        toast(`Lesson has been successfully deleted!`, successToastOptions)
        setLessonToDelete(null)

        fetchLessons()
      }
    } catch (e) {
      handleError(e)
    }
  }, [lessons, lessonToDelete])

  return (
    <div>
      <CourseLayout
        backText='Back'
        leftSideBar={!!course?.digital?.file}
        showSaveAsDraft={!lessons.length}
        viewOnly={loading || disableActions}
        onNavigate={direction => {
          if (!hasUnsavedData) {
            setInitialFormValues()
            onNavigate(direction)
          } else {
            setShouldGoTo({ direction })
            form.submitForm()
          }
        }}
        router={router}
        tabs={tabs}
        onBack={() => {
          if (!hasUnsavedData) {
            setInitialFormValues()
            onBack()
          } else {
            setShouldGoTo({ direction: 'prev' })
            form.submitForm()
          }
        }}
        onRemoveFormat={onRemoveFormat}
        setDeleteFormatModal={setDeleteFormatModal}
        deleteFormatModal={deleteFormatModal}
        isDisabled={disableActions}
        course={course}
        onNext={() => {
          if (!hasUnsavedData) {
            setInitialFormValues()
            goNext()
          } else {
            setShouldGoTo({ direction: 'next' })
            form.submitForm()
          }
        }}
        onSaveAsDraft={() => {
          setShouldGoTo({ direction: '/courses' })
          form.submitForm()
        }}
        classes={{
          cardBody: 'digital-course-container',
        }}
      >
        <Row className='w-100'>
          <div className='d-flex justify-content-end'>
            <div className='w-auto'>
              {!isPublished && allowToDeleteDigitalSettings && (
                <span
                  className='text-danger cursor-pointer d-flex gap-2 justify-content-end'
                  onClick={() => {
                    setDeleteFormatModal({
                      title: 'Delete Digital Settings',
                      message:
                        'Are you sure you want to delete Digital Settings?',
                      format: OnlineCourseFormatsEnum.DIGITAL,
                    })
                  }}
                >
                  <b>Remove Digital Settings</b>{' '}
                  <i className='bx bx-trash text-danger cursor-pointer	'></i>
                </span>
              )}
            </div>
          </div>

          <>
            {lessons.length ? (
              <Lessons
                courseId={course?.id as number}
                handleUploadLessons={handleUploadLessons}
                lessons={lessons}
                onReOrder={onReOrder}
                handleDelete={setLessonToDelete}
              />
            ) : (
              <>
                {!loading ? (
                  <Row className='mb-5'>
                    {!lessons.length && !course?.digital?.file && (
                      <Col className='rounded'>
                        <div style={{ height: '93%' }}>
                          <label htmlFor='upload-video' className='form-label'>
                            Upload a Video
                          </label>
                          <Dropzone
                            disabled={form.isSubmitting}
                            multiple={true}
                            accept={{
                              'video/mp4': [],
                            }}
                            onDrop={acceptedFiles => {
                              handleUploadLessons(acceptedFiles)
                            }}
                          >
                            {({ getRootProps }) => (
                              <div
                                className='dropzone dz-clickable cursor-pointer h-100 d-flex align-items-center'
                                style={{ minHeight: '100%' }}
                              >
                                <div
                                  className='dz-message needsclick h-100 d0flex align-items-center justify-content-center'
                                  {...getRootProps()}
                                >
                                  <div className='h-100 d-flex flex-column justify-content-center'>
                                    <div className='mt-2'>
                                      <i className='display-7 text-muted ri-upload-cloud-2-fill fs-25' />
                                    </div>
                                    <p className='m-0 text-muted fw-light fs-14'>
                                      Drop file here or click to upload.
                                      <br></br>
                                      (mp4)
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </Col>
                    )}

                    <Col>
                      {!course?.digital?.file ? (
                        <div>
                          <label
                            htmlFor='upload-storyline-course'
                            className='form-label'
                          >
                            Upload a Storyline Course
                          </label>
                          <div
                            className='py-5'
                            style={{ background: '#F6F9FE' }}
                          >
                            <img
                              src={BooksAndStethoscope}
                              className='img-fluid mx-auto d-flex'
                              alt='NO IMAGE'
                            />

                            <p
                              className='text-center my-3'
                              style={{
                                fontSize: 17,
                                color: isPublishing ? '#F25959' : 'black',
                              }}
                            >
                              No Course Uploaded yet
                            </p>
                            <ul
                              style={{ maxWidth: '425px' }}
                              className='mx-auto list-unstyled course-upload-steps'
                            >
                              {storylineCourseSteps.map((step, i) => (
                                <li
                                  className='badge-soft-grey d-flex p-2 mb-2 gap-2 rounded align-items-center'
                                  key={i}
                                >
                                  <span className='number-badge'>{i + 1}</span>
                                  <span className='ml-1'>{step}</span>
                                </li>
                              ))}
                            </ul>
                            <Button
                              color={'primary'}
                              className='btn btn-primary align-middle mx-auto d-block'
                              onClick={() => handleClick()}
                            >
                              <i className='ri-add-line me-1 fs-16'></i>Upload
                              Course
                            </Button>
                          </div>
                        </div>
                      ) : (
                        <section>
                          <div className='mx-auto'>
                            <ReplaceFile
                              disabled={false}
                              name={course?.digital?.file.name as string}
                              size={course?.digital?.file.size as number}
                              handleClick={handleClick}
                            />
                          </div>
                        </section>
                      )}
                      <input
                        type='file'
                        onChange={handleChange}
                        accept='.zip'
                        ref={hiddenFileInput}
                        style={{ display: 'none' }}
                      />
                    </Col>
                  </Row>
                ) : (
                  <CardBody>
                    <div className='d-flex flex-column align-items-center py-5'>
                      <p style={{ fontSize: 20, fontWeight: 600 }}>
                        Uploading...
                      </p>
                    </div>
                  </CardBody>
                )}
              </>
            )}
          </>

          {lessonToDelete && (
            <DeleteConfirmation
              isOpen={!!lessonToDelete}
              onClose={() => {
                setLessonToDelete(null)
              }}
              title='Delete Lesson'
              message={`Are you sure you want to delete lesson "${lessonToDelete?.title}"?`}
              onDelete={handleDelete}
            />
          )}
        </Row>
      </CourseLayout>
    </div>
  )
}
export default withRouter(DigitalCourse)
