import { CREDENTIALS_TYPE, TInstructor, TUser } from '../../sharedTypes'
import React from 'react'
import Highlighter from 'react-highlight-words'
import { getUserDisplayName } from '../../helpers/user'
import masteredLogo from '../../assets/images/mastered-icon.png'

interface UserInfoProps {
  user?: TUser | TInstructor | null
  globalSearch?: string
  hidePhoto?: boolean
}

type BadgeProps = {
  icon?: string
  iconClass?: string
  label: string
  bgColor?: string
  style?: React.CSSProperties
}

const Badge: React.FC<BadgeProps> = ({
  icon,
  iconClass,
  label,
  bgColor = 'badge-soft-primary',
  style = {},
}) => {
  return (
    <div
      style={{
        width: '41px',
        height: '17px',
        borderRadius: '24px',
        marginLeft: '4px',
        ...style,
      }}
      className={`d-flex align-items-center justify-content-center badge ${bgColor} fs-10 text-capitalize`}
    >
      {icon && (
        <img
          className='img-fluid'
          style={{ width: '12px', height: '12px' }}
          src={icon}
          alt={label}
        />
      )}
      {iconClass && <i className={`${iconClass} fw-normal`}></i>}
      {label}
    </div>
  )
}

const UserCell: React.FC<UserInfoProps> = ({
  user,
  globalSearch,
  hidePhoto,
}) => {
  if (!user) {
    return <td></td>
  }
  const email =
    user.credentials?.find(i => i.credentialType === CREDENTIALS_TYPE.EMAIL)
      ?.credential || ''
  return (
    <td>
      <div className='d-flex align-items-center'>
        {!hidePhoto && (
          <div className='flex-shrink-0'>
            {user.photo ? (
              <img
                src={user.photo}
                alt=''
                className='avatar-xs rounded-circle'
              />
            ) : (
              <div className='avatar-xs'>
                <div className='avatar-title rounded-circle bg-soft-primary text-primary'>
                  {user.firstName[0]} {user.lastName[0]}
                </div>
              </div>
            )}
          </div>
        )}
        <div className={`flex-grow-1 ${hidePhoto ? '' : 'ms-2'}`}>
          <p className='card-title mb-0 fs-14'>
            <div className='d-flex align-items-center'>
              <Highlighter
                highlightClassName='text-highlight'
                searchWords={[globalSearch || '']}
                highlightTag={'span'}
                autoEscape={true}
                textToHighlight={getUserDisplayName(user) || ''}
              />
              {user.id === user?.facility?.masteredManagerId && (
                <Badge icon={masteredLogo} label='MM' />
              )}{' '}
              {user.id === user?.facility?.HRManagerId && (
                <Badge iconClass='ri-group-line' label='HR' />
              )}{' '}
              {user.id === user?.facility?.clinicalSchedulerId && (
                <Badge iconClass='ri-calendar-2-line' label='CS' />
              )}
            </div>
          </p>
          <p className='text-muted mb-0 fs-12'>
            <Highlighter
              highlightClassName='text-highlight'
              searchWords={[globalSearch || '']}
              highlightTag={'span'}
              autoEscape={true}
              textToHighlight={
                user.isInstructor ? user.qualification.join(', ') : email
              }
            />
          </p>
        </div>
      </div>
    </td>
  )
}
export default UserCell
