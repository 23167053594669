import React, { useEffect, useState } from 'react'
import { Button, Card, CardBody, Col, Row, Table } from 'reactstrap'
import {
  DocumentFile,
  FacilityManagementPermissions,
  TAttachment,
  TFacility,
} from '../../../sharedTypes'
import { getFacilitySettings } from '../../../helpers/api_helper'
import { usePermissions } from '../../../hooks/usePermissions'
import formatBytes from '../../../utils/formatBytes'
import LandingPageModal from '../../../Components/Modals/LandingPageModal'
import { Link } from 'react-router-dom'

interface UserInfoProps {
  facility: TFacility
}

const LandingPageInfo = ({ facility: facilityProp }: UserInfoProps) => {
  const [facility, setFacility] = useState(facilityProp)
  const [showLandingPageModal, setShowLandingPageModal] = useState(false)
  const [file, setFile] = useState<DocumentFile | TAttachment | null>()
  const [message, setMessage] = useState<string>('')

  const hasPermissionToEdit = usePermissions(
    FacilityManagementPermissions.EDIT_FACILITY,
  )

  useEffect(() => {
    setFacility(facilityProp)
  }, [facilityProp])

  useEffect(() => {
    if (facility) {
      getFacilitySettings(facility.id).then(res => {
        setFile(res.data.video)
        setMessage(res.data.welcomeMessage)
      })
    }
  }, [facility])

  return (
    <Card>
      <CardBody className='d-flex flex-column'>
        <div className='d-flex align-items-center'>
          <div className='flex-grow-1'>
            <p className='fs-16 mb-0 fw-light' style={{ color: '#7C8193' }}>
              Facility Landing Page
            </p>
          </div>
          <div className='flex-shrink-0 fs-12'>
            {hasPermissionToEdit && (
              <Button
                color='soft-primary'
                className='fs-12'
                onClick={() => setShowLandingPageModal(true)}
              >
                <i
                  className={`${
                    file || message ? 'ri-edit-box-line' : 'ri-add-line'
                  } me-1 fs-12`}
                ></i>
                {file || message ? 'Edit' : 'Add Video'}
              </Button>
            )}
          </div>
        </div>
        <div className='table-responsive overflow-visible'>
          <Table
            className='table-borderless mb-0'
            style={{ tableLayout: 'fixed' }}
          >
            <tbody>
              <tr style={{ height: 39 }}>
                <div className='d-flex flex-column'>
                  {file && (
                    <td className='text-muted fw-light pe-0'>
                      <div className='p-2'>
                        <Row
                          className='align-items-center py-2'
                          style={{
                            border: '1px solid #E9EBEC',
                            borderRadius: '4px',
                          }}
                        >
                          <Col className='col-auto'>
                            <div className='d-flex align-items-center justify-content-center'>
                              <video
                                data-dz-thumbnail=''
                                height='80'
                                autoPlay={false}
                                controls={false}
                                className='avatar-sm rounded bg-light'
                                src={
                                  (file as DocumentFile).preview ||
                                  (file as TAttachment).path
                                }
                              />
                            </div>
                          </Col>
                          <Col>
                            <Link
                              to={(file as TAttachment).path}
                              className='text-muted font-weight-bold'
                            >
                              {file.name}
                            </Link>
                            <p className='mb-0'>
                              {formatBytes((file as TAttachment).size)}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    </td>
                  )}
                  {message && (
                    <td className='ps-0 fs-14 w-100 fw-normal' scope='row'>
                      <div className='d-flex flex-column'>
                        <span>Welcome Message</span>
                        <span className='text-muted'>{message}</span>
                      </div>
                    </td>
                  )}
                </div>
              </tr>
            </tbody>
          </Table>
        </div>
      </CardBody>
      <LandingPageModal
        onClose={() => setShowLandingPageModal(false)}
        isOpen={showLandingPageModal}
        facilityId={facility.id}
        onValuesSubmit={values => {
          setFile(values.file as unknown as DocumentFile)
          setMessage(values.welcomeMessage)
        }}
        initialValues={{
          file: file as unknown as File,
          welcomeMessage: message,
        }}
      />
    </Card>
  )
}

export default LandingPageInfo
