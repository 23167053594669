import { KNOWLEDGE_CHECK, LESSONS } from '../url_helper'
import { API } from '../api_helper'
import {
  AddKnowledgeCheckDto,
  GetKnowledgeChecksDto,
  GetLessonsDTO,
  TLesson,
  UpdateLessonOrderDto,
} from '../../sharedTypes/api/lessons'
import {
  IKnowledgeCheck,
  UpdateLessonDto,
} from '../../pages/Courses/OnlineCourses/Lessons/types'

export const updateLesson = (id: number, body: UpdateLessonDto) => {
  return API.patch<TLesson>(`${LESSONS}/${id}`, body).then(d => d.data)
}

export const createLesson = (courseId: number) => {
  return API.post<TLesson>(`${LESSONS}/${courseId}/create`)
}

export const uploadLessonVideo = (lessonId: number, body: FormData) => {
  return API.post<TLesson>(`${LESSONS}/${lessonId}/upload`, body, {
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(d => d.data)
}

export const updateLessonsOrder = (body: UpdateLessonOrderDto.Request) => {
  return API.post<TLesson>(`${LESSONS}/order`, body).then(d => d.data)
}

export const getLessons = (params: GetLessonsDTO.Request) => {
  return API.get<GetLessonsDTO.Response>(LESSONS, {
    params,
  }).then(d => d.data)
}

export const deleteLesson = (id: number) => {
  return API.delete(`${LESSONS}/${id}`)
}

export const getKnowledgeCheckByLessonId = (
  params: GetKnowledgeChecksDto.Request,
) => {
  return API.get(KNOWLEDGE_CHECK, {
    params,
  }).then(d => d.data)
}

export const createKnowledgeCheck = (body: AddKnowledgeCheckDto.Request) => {
  return API.post<IKnowledgeCheck>(KNOWLEDGE_CHECK, body).then(d => d.data)
}

export const updateKnowledgeCheck = (
  id: number,
  data: Partial<AddKnowledgeCheckDto.Request>,
) => {
  return API.patch(KNOWLEDGE_CHECK + `/${id}`, data)
}

export const deleteKnowledgeCheck = (id: number) => {
  return API.delete(`${KNOWLEDGE_CHECK}/${id}`)
}
