import React from 'react'
import { Button, Col } from 'reactstrap'
import { IGoTo } from '../../hooks/course/useShouldGoTo'
import { usePermissions } from '../../hooks/usePermissions'
import { CoursesPermissions, ONLINE_COURSE_STATUS } from '../../sharedTypes'

export interface BottomNavigationProps {
  onBack: () => void
  onSaveAsDraft: (props: IGoTo) => void
  onSubmit: () => void
  onPublish?: () => void
  backText: string
  viewOnly?: boolean
  hideNext?: boolean
  showSaveAsDraft?: boolean
  allowPublish?: boolean
  isPublished?: boolean
  status: ONLINE_COURSE_STATUS
}

export const BottomNavigation = ({
  backText,
  onBack,
  onSaveAsDraft,
  onSubmit,
  viewOnly,
  hideNext,
  onPublish,
  isPublished,
  showSaveAsDraft,
  allowPublish,
  status,
}: BottomNavigationProps) => {
  const hasPermissionToPublish = usePermissions(
    CoursesPermissions.PUBLISH_COURSE,
  )

  return (
    <Col sm='12' className='course-navigation'>
      <div className='d-flex justify-content-between'>
        <Button color='light' className='btn-label' onClick={onBack}>
          <i className='ri-arrow-left-line label-icon align-middle me-2'></i>
          {backText}
        </Button>
        <div className='hstack gap-3'>
          {showSaveAsDraft && status && (
            <Button
              disabled={viewOnly}
              color='ghost-primary'
              className='text-light-purple'
              onClick={() => onSaveAsDraft({ direction: 'next' })}
            >
              {status === ONLINE_COURSE_STATUS.DRAFT ? 'Save as Draft' : 'Save'}
            </Button>
          )}

          {!hideNext && (
            <Button
              disabled={viewOnly}
              color='primary'
              className='btn-label right'
              onClick={onSubmit}
            >
              <i className='ri-arrow-right-line label-icon align-middle ms-2'></i>
              Next
            </Button>
          )}

          {allowPublish && !viewOnly && hasPermissionToPublish && (
            <Button
              color='primary'
              className='btn-label right px-3'
              onClick={onPublish}
            >
              Publish
            </Button>
          )}
        </div>
      </div>
    </Col>
  )
}
