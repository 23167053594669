import React, { createContext, useContext, ReactNode, useMemo } from 'react'
import { io, Socket } from 'socket.io-client'
import { api } from '../config'

interface SocketContextType {
  socket: Socket | null
}

const SocketContext = createContext<SocketContextType | undefined>(undefined)

interface SocketProviderProps {
  children: ReactNode
}

export const SocketProvider: React.FC<SocketProviderProps> = ({ children }) => {
  const authUser = sessionStorage.getItem('authUser')
  const authToken = authUser ? JSON.parse(authUser).accessToken : null
  const API_URL = api.API_URL

  const socket = useMemo(() => {
    if (!authToken) {
      return null
    }

    return io(API_URL, {
      auth: {
        token: authToken,
      },
      transports: ['websocket'],
      secure: true,
      path: '/socket.io',
      forceNew: true,
      reconnectionAttempts: 3,
    })
  }, [authToken])

  return (
    <SocketContext.Provider value={{ socket }}>
      {children}
    </SocketContext.Provider>
  )
}

export const useSocket = (): Socket | null => {
  const context = useContext(SocketContext)
  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider')
  }

  return context.socket
}
