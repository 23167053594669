import React, { useCallback, useEffect } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalProps,
  Form,
  Col,
  Card,
  Row,
  Label,
  Input,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Spinner } from 'reactstrap'
import { toast } from 'react-toastify'
import Dropzone from 'react-dropzone'

import {
  updateFacilitySettings,
  uploadFacilityVideo,
} from '../../helpers/api_helper'
import formatBytes from '../../utils/formatBytes'

import { successToastOptions, handleError } from '../../helpers/toast_helper'
import { TAttachment } from '../../sharedTypes'

interface FileForm {
  file?: File
  welcomeMessage: string
}

interface LandingPageModalProps {
  onClose: () => void
  facilityId: number
  isOpen: ModalProps['isOpen']
  initialValues: FileForm
  onValuesSubmit?: (values: FileForm) => void
}

const maxFileSize = 1500000000

const LandingPageModal = ({
  onClose,
  facilityId,
  initialValues,
  onValuesSubmit,
  isOpen,
}: LandingPageModalProps) => {
  const form = useFormik<FileForm>({
    enableReinitialize: true,
    initialValues:
      initialValues.file || initialValues.welcomeMessage
        ? initialValues
        : {
            file: undefined,
            welcomeMessage: '',
          },
    validationSchema: Yup.object({
      file: Yup.mixed()
        .nullable()
        .required('Please select file')
        .test(
          'valid-size',
          'Max allowed size is 1.5GB',
          value => value?.size <= maxFileSize,
        ),
      welcomeMessage: Yup.string()
        .required('Welcome message is required')
        .max(1000),
    }),
    onSubmit: async values => {
      form.setSubmitting(true)
      if (onValuesSubmit) {
        onValuesSubmit(values)
      }
      await onUpload()
      await updateFacilitySettings(facilityId, {
        welcomeMessage: form.values.welcomeMessage,
      })
        .catch(handleError)
        .finally(() => {
          form.setSubmitting(false)
        })
      toast('Video submitted succesfully.', successToastOptions)
      onClose()
    },
  })

  const onUpload = useCallback(async () => {
    try {
      if (form.values.file) {
        await uploadFacilityVideo({
          file: form.values.file,
          facilityId: facilityId,
        })
      }
    } catch (e) {
      handleError(e)
    }
  }, [form.values.file])

  useEffect(() => {
    if (!isOpen) {
      form.resetForm()
    }
  }, [isOpen])

  const handleAcceptedFiles = (file: File) => {
    form.setFieldValue('file', file)
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered>
      <ModalBody className='modal-body'>
        <div className='hstack w-100 mb-4 flex-1 align-items-center justify-content-between'>
          <h5 className='fw-light'>Add Video</h5>
          <i
            className='ri-close-line fs-24 cursor-pointer'
            onClick={onClose}
          ></i>
        </div>
        <Form
          onSubmit={e => {
            e.preventDefault()
            form.handleSubmit()
            return false
          }}
          action='#'
        >
          <div className='gap-2 d-flex flex-column'>
            <div className='gap-2 d-flex flex-column'>
              <span className='fs-14'>Media</span>
              <Dropzone
                maxFiles={1}
                disabled={form.isSubmitting}
                multiple={false}
                accept={{
                  'video/mp4': [],
                }}
                onDrop={acceptedFiles => {
                  handleAcceptedFiles(acceptedFiles[0])
                }}
                maxSize={maxFileSize}
              >
                {({ getRootProps }) => (
                  <div className='dropzone dz-clickable cursor-pointer'>
                    <div className='dz-message needsclick' {...getRootProps()}>
                      <div className='mt-2'>
                        <i className='display-7 text-muted ri-upload-cloud-2-fill fs-25' />
                      </div>
                      <p className='m-0 text-muted fw-light fs-14'>
                        Drop file here or click to upload.<br></br>
                        (mp4)
                      </p>
                    </div>
                  </div>
                )}
              </Dropzone>
              {form.errors.file ? (
                <p style={{ color: '#F06548', fontSize: '0.875em' }}>
                  {form.errors.file}
                </p>
              ) : null}
              {form.values.file && (
                <ul className='list-unstyled mb-0' id='dropzone-preview'>
                  <Card
                    className='mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete'
                    key={'-file'}
                  >
                    <div className='p-2'>
                      <Row className='align-items-center'>
                        <Col className='col-auto'>
                          {form.values.file.type.includes('video/') ? (
                            <video
                              data-dz-thumbnail=''
                              height='80'
                              autoPlay={false}
                              controls={false}
                              className='avatar-sm rounded bg-light'
                              src={URL.createObjectURL(form.values.file)}
                            />
                          ) : form.values.file.type.includes('image/') ? (
                            <img
                              data-dz-thumbnail=''
                              height='80'
                              className='avatar-sm rounded bg-light'
                              alt={form.values.file.name}
                              src={URL.createObjectURL(form.values.file)}
                            />
                          ) : (
                            <div className='avatar-sm flex-shrink-0'>
                              <span className='avatar-title rounded fs-3 image-container'>
                                <i className='ri-file-list-3-line'></i>
                              </span>
                            </div>
                          )}
                        </Col>
                        <Col>
                          <Link to='#' className='text-muted font-weight-bold'>
                            {form.values.file.name}
                          </Link>
                          <p className='mb-0'>
                            {formatBytes(form.values.file.size)}
                          </p>
                        </Col>
                        <Col className='d-flex justify-content-end align-items-end'>
                          <Button
                            disabled={form.isSubmitting}
                            color='danger'
                            onClick={() => {
                              form.setFieldValue('file', undefined)
                            }}
                          >
                            Delete
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </ul>
              )}
            </div>
            <div className='gap-2 d-flex flex-column'>
              <Label className='form-check-label' htmlFor='welcomeMessage'>
                <div className='d-flex align-items-center justify-content-between'>
                  Welcome Message
                  <span
                    className={`${
                      form.values.welcomeMessage.length > 1000
                        ? 'text-danger'
                        : 'text-muted'
                    }`}
                  >
                    {form.values.welcomeMessage.length}/1000
                  </span>
                </div>
              </Label>
              <Input
                className='form-control'
                type='textarea'
                rows={4}
                value={form.values.welcomeMessage}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                name='welcomeMessage'
                id='exampleText'
                placeholder='Enter a welcome message'
                invalid={form.values.welcomeMessage.length > 1000}
              />
            </div>
          </div>

          <div className='hstack gap-2 justify-content-end mt-4'>
            <Button
              className='btn-soft-primary'
              onClick={onClose}
              active={false}
              disabled={form.isSubmitting}
            >
              Cancel
            </Button>
            <Button
              color='success'
              type='submit'
              disabled={!(form.isValid && form.dirty) || form.isSubmitting}
            >
              {form.isSubmitting ? <Spinner size={'sm'} /> : 'Save'}
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default LandingPageModal
