import React, { useCallback, useRef } from 'react'
import {
  Button,
  Col,
  FormFeedback,
  Input,
  Modal,
  ModalBody,
  ModalProps,
  Row,
} from 'reactstrap'
import * as yup from 'yup'
import {
  CREDENTIALS_TYPE,
  FilterOption,
  GetDepartmentsDTO,
  GetPositionsDTO,
  TDepartment,
  TPosition,
  TUser,
} from '../../sharedTypes'
import _ from 'lodash'
import Flatpickr from 'react-flatpickr'
import { AsyncSelectWithSearch } from '../Common/SelectWithSearch'
import { useFormik } from 'formik'
import moment from 'moment'
import {
  checkUserCredential,
  editUser,
  getDepartments,
  getFacilities,
  getPositions,
} from '../../helpers/api_helper'
import { handleError, successToastOptions } from '../../helpers/toast_helper'
import { toast } from 'react-toastify'
import DateOfBirthInput from '../Common/DateOfBirthInput'

interface EditUserModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  user: TUser
  onEdit: () => void
}

export interface Option {
  value: number
  label: string
}

export interface FormValues {
  id?: number
  dob: string | undefined
  firstName: string
  lastName: string
  position?: FilterOption
  facility?: FilterOption
  email: string
  phone: string
  department?: FilterOption
}

export const editUserSchema = yup.object({
  email: yup
    .string()
    .email('Invalid email format')
    .required('Email is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  dob: yup
    .string()
    .required('Date of Birth is required')
    .test('is-valid-dob', 'Invalid date of birth', function (value) {
      if (!value) {
        return this.createError({ message: 'Date of Birth is required' })
      }

      const formatRegex = /^\d{2}\/\d{2}\/\d{4}$/
      if (!formatRegex.test(value)) {
        return this.createError({
          message: 'Date of Birth should have mm/dd/yyyy format',
        })
      }

      const date = moment(value, 'MM/DD/YYYY', true)

      if (!date.isValid()) {
        return this.createError({
          message: 'Date of Birth must be a valid date',
        })
      }

      if (date.isSameOrAfter(moment(), 'day')) {
        return this.createError({
          message: 'Date of Birth must be less than today',
        })
      }

      return true
    }),
  position: yup.object().required('Position is required'),
  facility: yup.object().required('Facility is required'),
  department: yup.object().required('Department is required'),
  phone: yup
    .string()
    .matches(/^\d{10}$/, 'Mobile number must be exactly 10 digits'),
})

const EditUserModal = ({
  isOpen,
  onClose,
  onEdit,
  user,
}: EditUserModalProps) => {
  const initialValues: FormValues = {
    id: user.id,
    firstName: user.firstName,
    lastName: user.lastName,
    dob: moment(user.dob).format('MM/DD/YYYY'),
    position:
      user.position?.name && user.position?.id
        ? { label: user.position?.name, value: user.position?.id }
        : undefined,
    facility:
      user.facility?.name && user.facility?.id
        ? { label: user.facility?.name, value: user.facility?.id }
        : undefined,
    email:
      user?.credentials?.find(
        item => item.credentialType === CREDENTIALS_TYPE.EMAIL,
      )?.credential || '',
    phone:
      user?.credentials?.find(
        item => item.credentialType === CREDENTIALS_TYPE.PHONE,
      )?.credential || '',
    department:
      user.departments?.[0].name && user.departments?.[0].id
        ? {
            label: user.departments?.[0].name,
            value: user.departments?.[0].id,
          }
        : undefined,
  }

  const form = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: editUserSchema,
    onSubmit: async ({
      id,
      firstName,
      lastName,
      dob,
      department,
      facility,
      position,
      email,
      phone,
    }: FormValues) => {
      try {
        if (id) {
          await editUser(
            {
              firstName,
              lastName,
              dob,
              departmentId: department?.value ? +department?.value : undefined,
              facilityId: facility?.value ? +facility?.value : undefined,
              positionId: position?.value ? +position?.value : undefined,
              email,
              phone,
            },
            id,
          )
          form.resetForm()
          onClose()
          onEdit()
          toast('User data updated successfully.', successToastOptions)
        }
      } catch (error) {
        handleError(error)
      }
    },
  })

  const fetchFacilities = useCallback(
    (inputValue?: string) => {
      return getFacilities({ key: inputValue })
        .then(res => {
          return res.data.facilities.map(facility => {
            return {
              label: facility.name,
              value: facility.id,
            }
          })
        })
        .catch(() => [])
    },
    [form.values.facility],
  )

  const fetchDepartments = useCallback(
    (inputValue?: string) => {
      const query: GetDepartmentsDTO.Request = {
        key: inputValue,
      }
      if (form.values.facility) {
        query.facilityIds = [form.values.facility?.value as number]
      }
      return getDepartments(query)
        .then(res => {
          const grouped: Option[] = _.map(
            _.groupBy(res.data.departments, 'name'),
            (group: TDepartment[], key) => {
              return {
                label: key,
                value: group[0].id,
              }
            },
          )
          return grouped
        })
        .catch(() => [])
    },
    [form.values.facility],
  )

  const fetchPositions = useCallback(
    (inputValue?: string) => {
      const query: GetPositionsDTO.Request = {
        key: inputValue,
      }
      if (form.values.facility) {
        query.facilityIds = [form.values.facility?.value as number]
      }
      return getPositions(query).then(res => {
        const grouped: Option[] = _.map(
          _.groupBy(res.data.positions, 'name'),
          (group: TPosition[], key) => {
            return {
              label: key,
              value: group[0].id,
            }
          },
        )
        return grouped
      })
    },
    [form.values.facility],
  )

  const checkCredential = useCallback(() => {
    if (form.values.email || form.values.phone) {
      if (form.values.email && initialValues.email !== form.values.email) {
        checkUserCredential({
          credentialType: CREDENTIALS_TYPE.EMAIL,
          email: form.values.email,
        })
          .then(res => {
            if (res.data) {
              form.setFieldError(
                'email',
                'This email already matches a current user in the system.',
              )
            }
          })
          .catch(handleError)
      }

      if (form.values.phone && initialValues.phone !== form.values.phone) {
        checkUserCredential({
          credentialType: CREDENTIALS_TYPE.PHONE,
          phone: form.values.phone,
        })
          .then(res => {
            if (res.data) {
              form.setFieldError(
                'phone',
                'This phone number already matches a current user in the system.',
              )
            }
          })
          .catch(handleError)
      }
    }
  }, [form.values.email, form.values.phone])

  return (
    <Modal isOpen={isOpen} toggle={onClose} centered size='lg'>
      <ModalBody className='p-0'>
        <div className='px-3 py-2 border-bottom'>
          <div className='hstack w-100 flex-1 align-items-center justify-content-end gap-5'>
            <h5 className='flex-1 fw-light m-0'>Edit User</h5>
            <div
              className='flex-1 position-relative align-items-center justify-content-end'
              style={{ maxWidth: 250 }}
            >
              <div
                className='hstack justify-content-between'
                style={{ zIndex: 100 }}
              ></div>
            </div>
            <i
              className='ri-close-line fs-24 cursor-pointer'
              onClick={onClose}
            ></i>
          </div>
        </div>
        <div className='my-3 mt-0 mx-0 pt-2 px-3 add-user-details'>
          <div className='vstack gap-3'>
            <Row>
              <Col>
                <label htmlFor='firstName' className='form-label'>
                  First Name*
                </label>
                <Input
                  name='firstName'
                  className='form-control'
                  id='firstName'
                  placeholder='Enter name'
                  type='text'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.firstName}
                  invalid={!!(form.touched.firstName && form.errors.firstName)}
                />
                {form.touched.firstName && form.errors.firstName ? (
                  <FormFeedback type='invalid'>
                    {form.errors.firstName}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col>
                <label htmlFor='lastName' className='form-label'>
                  Last Name*
                </label>
                <Input
                  name='lastName'
                  className='form-control'
                  id='lastName'
                  placeholder='Enter last name'
                  type='text'
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.lastName}
                  invalid={!!(form.touched.lastName && form.errors.lastName)}
                />
                {form.touched.lastName && form.errors.lastName ? (
                  <FormFeedback type='invalid'>
                    {form.errors.lastName}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor='dob' className='form-label'>
                  Date of Birth*
                </label>
                <DateOfBirthInput
                  handleBlur={form.handleBlur}
                  value={form.values.dob}
                  name='dob'
                  className='form-control'
                  id='birthday'
                  placeholder='mm/dd/yyyy'
                  onBlur={form.handleBlur}
                  handleChange={value => {
                    form.setFieldValue('dob', value)
                  }}
                  invalid={!!(form.touched.dob && form.errors.dob)}
                />
                {form.touched.dob && form.errors.dob ? (
                  <FormFeedback type='invalid' className='d-block'>
                    {form.errors.dob}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col>
                <label htmlFor='facility' className='form-label'>
                  Facility*
                </label>
                <AsyncSelectWithSearch
                  name='facility'
                  id='facilities'
                  onChange={option => {
                    form.setFieldValue('facility', option)
                    form.setFieldValue('department', undefined)
                    form.setFieldValue('position', undefined)
                  }}
                  onBlur={form.handleBlur}
                  value={form.values.facility}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={true}
                  placeholder={'Select facility'}
                  defaultOptions
                  loadOptions={fetchFacilities}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                  className={`${
                    form.touched.facility && form.errors.facility
                      ? ' is-invalid'
                      : ''
                  }`}
                  menuPortalTarget={document.body}
                />

                {form.touched.facility && form.errors.facility ? (
                  <FormFeedback type='invalid' className='d-block'>
                    {form.errors.facility}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col>
                <label htmlFor='department' className='form-label'>
                  Department*
                </label>
                <AsyncSelectWithSearch
                  name='department'
                  id='department'
                  onChange={option => {
                    form.setFieldValue('department', option)
                  }}
                  onBlur={form.handleBlur}
                  value={form.values.department}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={true}
                  placeholder={'Select department'}
                  defaultOptions
                  loadOptions={fetchDepartments}
                  key={JSON.stringify(form.values.facility)}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                  className={`${
                    form.touched.department && form.errors.department
                      ? ' is-invalid'
                      : ''
                  }`}
                  menuPortalTarget={document.body}
                />
                {form.touched.department && form.errors.department ? (
                  <FormFeedback type='invalid' className='d-block'>
                    {form.errors.department}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col>
                <label htmlFor='position' className='form-label'>
                  Position*
                </label>
                <AsyncSelectWithSearch
                  name='position'
                  id='position'
                  onChange={option => {
                    form.setFieldValue('position', option)
                  }}
                  onBlur={form.handleBlur}
                  value={form.values.position}
                  isMulti={false}
                  isClearable={false}
                  isSearchable={true}
                  placeholder={'Select position'}
                  defaultOptions
                  loadOptions={fetchPositions}
                  key={JSON.stringify(form.values.facility)}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                  className={`${
                    form.touched.position && form.errors.facility
                      ? ' is-invalid'
                      : ''
                  }`}
                  menuPortalTarget={document.body}
                />
                {form.touched.position && form.errors.position ? (
                  <FormFeedback type='invalid' className='d-block'>
                    {form.errors.position}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col>
                <label htmlFor='phone' className='form-label'>
                  Phone Number*
                </label>
                <Input
                  name='phone'
                  className='form-control'
                  id='phone'
                  placeholder='_ _ _ - _ _ _ - _ _ _ _'
                  type='text'
                  onChange={e => {
                    form.handleChange(e)
                  }}
                  onBlur={e => {
                    form.handleBlur(e)
                    checkCredential()
                  }}
                  invalid={!!(form.touched.phone && form.errors.phone)}
                  value={form.values.phone}
                />
                {form.touched.phone && form.errors.phone ? (
                  <FormFeedback type='invalid'>
                    {form.errors.phone}
                  </FormFeedback>
                ) : null}
              </Col>
              <Col>
                <label htmlFor='email' className='form-label'>
                  Email address*
                </label>
                <Input
                  name='email'
                  className='form-control'
                  id='name'
                  placeholder='user@email.com'
                  type='text'
                  onChange={e => {
                    form.handleChange(e)
                  }}
                  onBlur={e => {
                    form.handleBlur(e)
                    checkCredential()
                  }}
                  value={form.values.email}
                  invalid={!!(form.touched.email && form.errors.email)}
                />
                {form.touched.email && form.errors.email ? (
                  <FormFeedback type='invalid'>
                    {form.errors.email}
                  </FormFeedback>
                ) : null}
              </Col>
            </Row>
          </div>
          <div className='mt-3'>
            <div className='hstack gap-2 justify-content-end'>
              <Button
                className='btn-soft-primary align-middle'
                color='secondary'
                onClick={onClose}
              >
                Close
              </Button>
              <Button
                className='align-middle'
                color='primary'
                disabled={!form.isValid || !form.dirty || form.isSubmitting}
                onClick={() => form.handleSubmit()}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default EditUserModal
