import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  UncontrolledAccordion,
} from 'reactstrap'
import classnames from 'classnames'
import React, { useEffect, useRef } from 'react'
import Dropzone from 'react-dropzone'
import { useSocket } from '../../../../context/SocketContext'
import Lesson from './Lesson'
import { LessonsProps } from './types'

const Lessons = ({
  lessons,
  handleUploadLessons,
  onReOrder,
  handleDelete,
}: LessonsProps) => {
  const latestLessonsRef = useRef([...lessons])

  const socket = useSocket()

  useEffect(() => {
    latestLessonsRef.current = lessons
  }, [lessons])

  return (
    <div className='lessons-container mb-4'>
      <DragDropContext onDragEnd={onReOrder}>
        <Droppable
          droppableId='droppable'
          type='questions'
          direction='vertical'
        >
          {provided => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {provided.placeholder}
              {lessons.map((lesson, i: number) => (
                <Lesson
                  i={i}
                  key={i}
                  lesson={lesson}
                  socket={socket}
                  onDelete={handleDelete}
                />
              ))}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <UncontrolledAccordion flush>
        <AccordionItem className='add-lesson-accordion-item'>
          <AccordionHeader targetId='1' className='p-0'>
            <h2 className='py-0 m-0 add-lesson-header'>
              <div
                className={classnames(
                  'accordion-button w-100 shadow-none py-2',
                )}
              >
                <div className='d-flex justify-content-between w-100 p-2'>
                  <div className='d-flex align-items-center w-100 gap-2 ms-2'>
                    <span className='text-white rounded px-3 py-2 lesson-number'>
                      {(lessons?.length as number) + 1}
                    </span>
                    <i className='ri-graduation-cap-line px-2 py-1 bg-white fs-3 rounded add-lesson-icon' />
                    <span className='text-primary ms-2 fw-medium'>
                      + Add Lesson
                    </span>
                  </div>
                </div>
              </div>
            </h2>
          </AccordionHeader>
          <AccordionBody accordionId='1'>
            <Dropzone
              multiple={true}
              accept={{
                'video/mp4': [],
              }}
              onDrop={acceptedFiles => {
                handleUploadLessons(acceptedFiles)
              }}
            >
              {({ getRootProps }) => (
                <div
                  className='dropzone dz-clickable cursor-pointer h-100 d-flex align-items-center'
                  style={{ minHeight: '100%' }}
                >
                  <div
                    className='dz-message needsclick h-100 d-flex align-items-center justify-content-center'
                    {...getRootProps()}
                  >
                    <div className='h-100 d-flex flex-column justify-content-center'>
                      <div className='mt-2'>
                        <i className='display-7 text-muted ri-upload-cloud-2-fill fs-25' />
                      </div>
                      <p className='m-0 text-muted fw-light fs-14'>
                        Drop file here or click to upload.<br></br>
                        (mp4)
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </Dropzone>
          </AccordionBody>
        </AccordionItem>
      </UncontrolledAccordion>
    </div>
  )
}

export default Lessons
