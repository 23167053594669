import moment from 'moment'
import React, { Fragment } from 'react'
import { Col, Input, Label } from 'reactstrap'
import { CREDENTIALS_TYPE, TUserCredentials } from '../../../../sharedTypes'
import { TDepartment } from '../../../../sharedTypes'

type InputValueType = string | string[] | undefined

interface UserData<T extends InputValueType = InputValueType> {
  firstName: string
  lastName: string
  dob?: Date | undefined | string
  facility?: { name: string } | null
  departments?: TDepartment[] | undefined
  position?: { name: string } | null
  credentials?: TUserCredentials[]
  phones?: T[]
  emails?: T[]
}

interface UserCardListProps<T extends InputValueType = InputValueType> {
  user: UserData<T> | null
  matchedUser: UserData<T> | null
  mastered?: boolean
}

const UserAccountDataCard = <T extends InputValueType = InputValueType>({
  user,
  matchedUser,
  mastered = false,
}: UserCardListProps<T>) => {
  return (
    <Fragment>
      <Col className='mb-3'>
        <Label className='form-label'>First Name*</Label>
        <div
          style={{
            ...(mastered && user?.firstName !== matchedUser?.firstName
              ? { border: '1px solid #0039c7', borderRadius: '4px' }
              : {}),
          }}
          className='form-icon right'
        >
          <Input
            className='form-control'
            id='firstName'
            name='firstName'
            placeholder=''
            type='text'
            disabled={true}
            value={user?.firstName}
            readOnly
          />
        </div>
      </Col>
      <Col className='mb-3'>
        <Label className='form-label'>Last Name*</Label>
        <div
          style={{
            ...(mastered && user?.lastName !== matchedUser?.lastName
              ? { border: '1px solid #0039c7', borderRadius: '4px' }
              : {}),
          }}
          className='form-icon right'
        >
          <Input
            className='form-control'
            id='lastName'
            name='lastName'
            placeholder=''
            type='text'
            disabled={true}
            value={user?.lastName}
            readOnly
          />
        </div>
      </Col>
      <Col className='mb-3'>
        <Label className='form-label'>Date of Birth</Label>
        <div
          style={{
            ...(mastered &&
            moment(user?.dob).format('DD/MM/YYYY') !==
              moment(matchedUser?.dob).format('DD/MM/YYYY')
              ? { border: '1px solid #0039c7', borderRadius: '4px' }
              : {}),
          }}
          className='form-icon right'
        >
          <Input
            className='form-control'
            id='dob'
            name='dob'
            placeholder=''
            type='text'
            disabled={true}
            value={user?.dob ? moment(user?.dob).format('DD/MM/YYYY') : ''}
            readOnly
          />
        </div>
      </Col>
      <Col className='mb-3'>
        <Label className='form-label'>Facility</Label>
        <div
          style={{
            ...(mastered &&
            user?.facility?.name !== matchedUser?.facility?.name &&
            matchedUser?.facility?.name
              ? { border: '1px solid #0039c7', borderRadius: '4px' }
              : {}),
          }}
          className='form-icon right'
        >
          <Input
            className='form-control'
            id='facility'
            name='facility'
            placeholder=''
            type='text'
            disabled={true}
            value={user?.facility?.name}
            readOnly
          />
        </div>
      </Col>
      <Col className='mb-3'>
        <Label className='form-label'>Department</Label>
        <div
          style={{
            ...(mastered &&
            user &&
            matchedUser &&
            user.departments?.length &&
            matchedUser.departments?.length &&
            user.departments[0]?.name !== matchedUser.departments[0]?.name
              ? { border: '1px solid #0039c7', borderRadius: '4px' }
              : {}),
          }}
          className='form-icon right'
        >
          <Input
            className='form-control'
            id='departments'
            name='departments'
            placeholder=''
            type='text'
            disabled={true}
            value={
              user && user.departments?.length ? user.departments[0]?.name : ''
            }
            readOnly
          />
        </div>
      </Col>
      <Col className='mb-3'>
        <Label className='form-label'>Position</Label>
        <div
          style={{
            ...(mastered &&
            user?.position?.name !== matchedUser?.position?.name &&
            matchedUser?.position?.name
              ? { border: '1px solid #0039c7', borderRadius: '4px' }
              : {}),
          }}
          className='form-icon right'
        >
          <Input
            className='form-control'
            id='position'
            name='position'
            placeholder=''
            type='text'
            disabled={true}
            value={user?.position?.name}
            readOnly
          />
        </div>
      </Col>
      {user?.credentials
        ?.filter(item => item.credentialType === CREDENTIALS_TYPE.EMAIL)
        .map((item, index: number) => (
          <Col key={index} className='mb-3'>
            <div className='d-flex justify-content-between'>
              <Label className='form-label'>
                Email address {index > 0 ? `#${index}` : ''}
              </Label>
              {!((matchedUser?.emails || []) as string[]).includes(
                item.credential,
              ) && <span className='text-primary'>*Will be added</span>}
            </div>

            <Input
              className='form-control'
              id='email'
              type='text'
              disabled={true}
              value={item.credential}
              readOnly
            />
          </Col>
        ))}

      {user?.credentials
        ?.filter(item => item.credentialType === CREDENTIALS_TYPE.PHONE)
        .map((item, index: number) => (
          <Col key={index} className='mb-3'>
            <div className='d-flex justify-content-between'>
              <Label className='form-label'>
                Phone Number {index > 0 ? `#${index}` : ''}
              </Label>
              {!((matchedUser?.phones || []) as string[]).includes(
                item.credential,
              ) && <span className='text-primary'>*Will be added</span>}
            </div>
            <Input
              className='form-control'
              id='phone'
              name='phone'
              placeholder=''
              type='text'
              disabled={true}
              value={item.credential}
              readOnly
            />
          </Col>
        ))}
      {user?.emails?.map((email, index: number) => (
        <Col key={index} className='mb-3'>
          <Label className='form-label'>
            Email Address {index > 0 ? `#${index}` : ''}
          </Label>
          <Input
            className='form-control'
            id='email'
            type='text'
            disabled={true}
            value={email}
            readOnly
          />
        </Col>
      ))}

      {user?.phones?.map((phone, index: number) => (
        <Col key={index} className='mb-3'>
          <Label className='form-label'>
            Phone Number {index > 0 ? `#${index}` : ''}
          </Label>
          <Input
            className='form-control'
            id='email'
            type='text'
            disabled={true}
            value={phone}
            readOnly
          />
        </Col>
      ))}
    </Fragment>
  )
}

export default UserAccountDataCard
