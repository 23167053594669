import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  Button,
  Collapse,
  Form,
  Modal,
  ModalBody,
  ModalProps,
} from 'reactstrap'
import { Formik, FormikProps } from 'formik'
import { CollapseHeaderProps } from '../AssignCourseModal/types'
import { COURSE_TYPES, CreateUserDto } from '../../../sharedTypes'
import Step1 from './Step1'
import { FormValues, initialPackagesData, Step2Filters } from './types'
import { addNewUserSchema } from '../../../schemas'
import Step1Passed from './Step1Passed'
import Step2 from './Step2'
import { GetCoursePackagesDTO } from '../../../sharedTypes/api/coursePackages'
import { CoursePackageStatus } from '../../../sharedTypes/models/coursePackage'
import { getCoursePackages } from '../../../helpers/api/coursePackages'
import { toast } from 'react-toastify'
import {
  errorToastOptions,
  handleError,
  successToastOptions,
} from '../../../helpers/toast_helper'
import Step2Passed from './Step2Passed'
import Confirmation from './Step3'
import { createUser } from '../../../helpers/api/users'
import moment from 'moment/moment'

const initialValues: FormValues = {
  user: {
    firstName: '',
    lastName: '',
    dob: undefined,
    position: undefined,
    facility: undefined,
    email: '',
    phone: '',
    department: undefined,
  },
  packages: {
    data: [],
    expirationDate: undefined,
  },
  confirmation: {
    facilityTourDate: undefined,
    facilityTourStartTime: undefined,
    facilityTourEndTime: undefined,
    scheduleOnboarding: false,
    scheduleFacilityTour: false,
    onboardingDate: undefined,
    onboardingTime: undefined,
  },
}

const initialPackageFilters: Step2Filters = {
  limit: 10,
}

interface AddNewUserModalProps {
  onClose: () => void
  isOpen: ModalProps['isOpen']
  onAdd: () => void
}

const AddNewUserModal = ({ isOpen, onClose, onAdd }: AddNewUserModalProps) => {
  const [activeStep, setActiveStep] = useState<number>(0)
  const [packageFilters, setPackageFilters] = useState(initialPackageFilters)
  const [isLoading, setIsLoading] = useState(false)
  const [packagesData, setPackagesData] = useState(initialPackagesData)

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
    }
  }, [packageFilters, isOpen])

  const formikFormRef = useRef<FormikProps<FormValues>>(null)

  const _onClose = () => {
    formikFormRef.current?.resetForm()
    setActiveStep(0)
    setPackageFilters(initialPackageFilters)
    setIsLoading(false)
    setPackagesData(initialPackagesData)
    onClose()
  }

  const fetchPackagesData = (page?: number) => {
    const query: GetCoursePackagesDTO.Request = {
      page: page || packagesData.page + 1,
      limit: packageFilters.limit,
      key: packageFilters.key,
      status: [CoursePackageStatus.PUBLISHED],
      type: [COURSE_TYPES.ONBOARDING],
      disciplineIds: packageFilters.disciplineIds,
    }
    getCoursePackages(query)
      .then(res => ({
        data: res.content,
        page: res.page,
      }))
      .then(({ page, data }) => {
        setPackagesData(prev => ({
          ...prev,
          data: page > 1 ? [...packagesData.data, ...data] : data,
          page,
        }))
      })
      .catch(() => {
        toast('Something went wrong', errorToastOptions)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const onStepBack = useCallback(() => {
    if (activeStep >= 1) {
      setActiveStep(activeStep - 1)
    }
  }, [activeStep])

  const onStepSubmit = useCallback(() => {
    if (activeStep < 3) {
      setActiveStep(activeStep + 1)
    }
  }, [activeStep])

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true)
      fetchPackagesData(1)
    }
  }, [packageFilters, isOpen])

  const onCreate = () => {
    const values = formikFormRef.current?.values

    if (!values) {
      return
    }

    setIsLoading(true)
    const data: CreateUserDto.Request = {
      user: {
        firstName: values.user.firstName,
        lastName: values.user.lastName,
        dob: values.user.dob as string,
        facilityId: values.user.facility?.value as number,
        departmentId: values.user.department?.value as number,
        positionId: values.user.position?.value as number,
        email: values.user.email,
        phone: values.user.phone,
      },
    }

    if (values.packages.data?.length) {
      data.packages = {
        data: values.packages.data.map(item => item.id),
        expirationDate: values.packages.expirationDate,
      }
    }

    if (values.confirmation) {
      data.confirmation = {}

      if (values.confirmation.scheduleOnboarding) {
        data.confirmation = {
          onboardingDate: moment(values.confirmation.onboardingDate)
            .set('hours', moment(values.confirmation.onboardingTime).hours())
            .set('minute', moment(values.confirmation.onboardingTime).minutes())
            .toDate(),
        }
      }

      if (values.confirmation.scheduleFacilityTour) {
        if (values.confirmation.facilityTourSchedule) {
          data.confirmation.facilityTourScheduleId =
            values.confirmation.facilityTourSchedule.value
          data.confirmation.facilityTourDate =
            values.confirmation.facilityTourDate
        } else {
          data.confirmation.facilityTourDate = moment(
            values.confirmation.facilityTourDate,
          )
            .set(
              'hours',
              moment(values.confirmation.facilityTourStartTime).hours(),
            )
            .set(
              'minute',
              moment(values.confirmation.facilityTourStartTime).minutes(),
            )
            .toDate()
          data.confirmation.facilityTourStartTime =
            values.confirmation.facilityTourStartTime
          data.confirmation.facilityTourEndTime =
            values.confirmation.facilityTourEndTime
        }
      }
    }

    createUser(data)
      .then(() => {
        toast('User has been created successfully', successToastOptions)
        onAdd()
        _onClose()
      })
      .catch(handleError)
      .finally(() => {
        setIsLoading(false)
      })
  }

  const showSteps = useMemo(
    () => ({
      step1: activeStep === 0,
      step2: activeStep === 1,
      step3: activeStep === 2,
    }),
    [activeStep],
  )

  const formInitialValues = useMemo(() => {
    return initialValues
  }, [packagesData])

  return (
    <Modal isOpen={isOpen} toggle={_onClose} centered size={'xl'}>
      <ModalBody className='p-0'>
        <div className='px-3 py-2 border-bottom'>
          <div className='hstack w-100 flex-1 align-items-center justify-content-end gap-5'>
            <h5 className='flex-1 fw-light m-0'>Assign Packages</h5>
            <div
              className='flex-1 position-relative align-items-center justify-content-end'
              style={{ maxWidth: 250 }}
            >
              <div
                className='hstack justify-content-between'
                style={{ zIndex: 100 }}
              ></div>
            </div>
            <i
              className='ri-close-line fs-24 cursor-pointer'
              onClick={_onClose}
            ></i>
          </div>
        </div>
        <Formik<FormValues>
          enableReinitialize={false}
          initialValues={formInitialValues}
          onSubmit={onCreate}
          validationSchema={addNewUserSchema}
          innerRef={formikFormRef}
        >
          {({
            values,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            setFieldError,
            setFieldTouched,
            errors,
            isValid,
          }) => (
            <Form className='position-relative assign-courses-form'>
              <div className='assign-step rounded mx-3 my-2 px-3 py-2 bg-light'>
                <CollapseHeader
                  label={'Add new user'}
                  icon={'bx bx-user-plus'}
                  stepPassed={!showSteps.step1}
                  changeActiveStep={() => setActiveStep(0)}
                />
                {showSteps.step1 ? (
                  <Collapse isOpen={true}>
                    <Step1
                      values={values.user}
                      setFieldValue={setFieldValue}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                      handleBlur={handleBlur}
                      handleChange={handleChange}
                      touched={touched.user || {}}
                      errors={errors.user || {}}
                      isValid={isValid}
                      onCancel={_onClose}
                      onNext={onStepSubmit}
                    />
                  </Collapse>
                ) : (
                  <Step1Passed userData={values.user} />
                )}
              </div>

              <div className='assign-step rounded mx-3 my-2 bg-light p-3'>
                <CollapseHeader
                  label={'Assign Course Packages'}
                  icon={'ri-file-copy-line'}
                  stepPassed={showSteps.step3}
                  changeActiveStep={() => setActiveStep(1)}
                />
                <Collapse isOpen={!showSteps.step1}>
                  {showSteps.step2 ? (
                    <Step2
                      values={values.packages}
                      errors={errors.packages || {}}
                      touched={touched.packages || {}}
                      setFieldTouched={setFieldTouched}
                      filters={packageFilters}
                      isLoading={isLoading}
                      isValid={isValid}
                      setFilters={setPackageFilters}
                      setFieldValue={setFieldValue}
                      onCancel={onStepBack}
                      onNext={onStepSubmit}
                      packagesData={packagesData}
                    />
                  ) : (
                    <Step2Passed packages={values.packages.data || []} />
                  )}
                </Collapse>
              </div>
              <div className='assign-step rounded mx-3 my-2 bg-light p-3'>
                <CollapseHeader
                  label={'Confirmation'}
                  icon={'bx bx-badge-check'}
                  stepPassed={false}
                  changeActiveStep={() => setActiveStep(3)}
                />
                <Collapse isOpen={showSteps.step3}>
                  <Confirmation
                    values={values.confirmation}
                    facilityId={values.user.facility?.value as number}
                    errors={errors.confirmation || {}}
                    setFieldValue={setFieldValue}
                    isValid={isValid}
                    isLoading={isLoading}
                    onNext={onCreate}
                    onCancel={onStepBack}
                  />
                </Collapse>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  )
}

const CollapseHeader = ({
  label,
  icon,
  stepPassed,
  changeActiveStep,
}: CollapseHeaderProps) => {
  return (
    <div className='d-flex align-items-center fs-20 gap-2 justify-content-between'>
      <div className='d-flex align-items-center fs-20 gap-2'>
        <div className='avatar-xs flex-shrink-0'>
          <span
            className={`avatar-title rounded-circle assign-course-step-icon
        ${stepPassed ? 'selected' : ''} `}
          >
            {stepPassed ? (
              <i className='bx bx-check text-green-500'></i>
            ) : (
              <i className={`${icon} text-primary`}></i>
            )}
          </span>
        </div>
        <p className={`fw-semibold m-0 ${stepPassed ? 'text-green-500' : ''}`}>
          {label}
        </p>
      </div>

      {stepPassed && (
        <div className='flex-shrink-0 fs-12'>
          <Button
            color='soft-primary'
            className='fs-12 p-1 fw-medium'
            onClick={changeActiveStep}
          >
            <i className='ri-edit-box-line me-1'></i>Edit
          </Button>
        </div>
      )}
    </div>
  )
}
export default AddNewUserModal
