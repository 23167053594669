import React, { useState, useCallback, useEffect } from 'react'
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  Input,
  Label,
  TabContent,
  TabPane,
  FormFeedback,
  Button,
} from 'reactstrap'
import classnames from 'classnames'
import { get } from 'lodash'
import {
  TLanguage,
  QuestionTypes,
  IQuestionForm,
  ImageAnswer,
  TextAnswer,
} from '../../../sharedTypes'
import Select from 'react-select'
import { useFormik, FormikProvider, FieldArray } from 'formik'
import { TextAnswers } from './TextAnswers'
import { ImageAnswers } from './ImageAnswers'
import { BoolAnswer } from './BoolAnswer'
import { courseQuestionsSchema } from '../../../schemas'
import { TAttachment } from '../../../sharedTypes'
import { DropResult } from 'react-beautiful-dnd'

const options = [
  { label: QuestionTypes.IMAGE_CHOICE, value: QuestionTypes.IMAGE_CHOICE },
  {
    label: QuestionTypes.MULTIPLE_CHOICE,
    value: QuestionTypes.MULTIPLE_CHOICE,
  },
  { label: QuestionTypes.TRUE_FALSE, value: QuestionTypes.TRUE_FALSE },
  { label: QuestionTypes.SHORT_ANSWER, value: QuestionTypes.SHORT_ANSWER },
]

export const CreateQuestion = ({
  languages,
  onCancel,
  onSubmit,
  initialValues,
  defaultType = QuestionTypes.TRUE_FALSE,
  disableTypeSelection,
  hideBottomAtions,
  hideCorrectAnswer,
  onChange,
  triggerValidation,
  maxMultiChoiceQuestions,
  allowedTypes = [
    QuestionTypes.TRUE_FALSE,
    QuestionTypes.IMAGE_CHOICE,
    QuestionTypes.MULTIPLE_CHOICE,
  ],
}: {
  loading?: boolean
  onCancel: () => void
  onSubmit: (data: IQuestionForm) => void
  onChange?: (data: IQuestionForm) => void
  languages: TLanguage[]
  initialValues?: IQuestionForm | null
  defaultType?: QuestionTypes
  disableTypeSelection?: boolean
  hideBottomAtions?: boolean
  hideCorrectAnswer?: boolean
  triggerValidation?: string | null
  maxMultiChoiceQuestions?: number
  allowedTypes?: QuestionTypes[]
}) => {
  const [prevType, setPrevType] = useState<QuestionTypes>(
    initialValues ? initialValues.type : defaultType,
  )
  const defaultMaxMultiChoiceQuestions = 4
  const [activeLanguage, setActiveLanguage] = useState<null | number>(
    get(languages, '[0].id', null),
  )

  const form = useFormik<IQuestionForm>({
    initialValues: initialValues || {
      type: defaultType,
      questions: languages.map(l => ({
        languageId: l.id,
        question: '',
        answers: [],
      })),
    },
    onSubmit,
    validationSchema: courseQuestionsSchema,
  })

  useEffect(() => {
    if (triggerValidation) {
      form.handleSubmit()
    }
  }, [triggerValidation])

  useEffect(() => {
    if (onChange) {
      onChange(form.values)
    }
  }, [form.values])

  const onReOrder = useCallback(
    async (result: DropResult, index: number) => {
      if (!result.destination) {
        return
      }

      const answers = Array.from(form.values.questions[index].answers || [])

      const [reorderedItem] = answers.splice(result.source.index, 1)

      answers.splice(result.destination.index, 0, reorderedItem)

      form.setFieldValue(`questions[${index}].answers`, answers)
    },
    [form.values],
  )

  const onImageAnswerChange = useCallback(
    (qIndex: number, aIndex: number, attachment: TAttachment) => {
      const answer = get(
        form,
        `values.questions[${qIndex}].answers[${aIndex}]`,
        {},
      )
      form.setFieldValue(`questions[${qIndex}].answers[${aIndex}]`, {
        ...answer,
        path: attachment.path,
        size: attachment.size,
        name: attachment.name,
        attachmentId: attachment.id,
      })
    },
    [form.values],
  )

  const onTextAnswerChange = useCallback(
    (qIndex: number, aIndex: number, value: string | boolean) => {
      form.setFieldValue(
        `questions[${qIndex}].answers[${aIndex}].answer`,
        value,
      )
    },
    [],
  )

  const getAnswerErrors = useCallback(
    (index: number) => get(form, `errors.questions[${index}].answers`, []),
    [form],
  )

  const getTouchedAnswers = useCallback(
    (index: number): any =>
      get(form, `touched.questions[${index}].answers`, []) || [],
    [form],
  )

  const testFunction = useCallback(() => {
    if (form.values.type !== prevType) {
      const questions = form.values.questions.map((prev, index) => {
        const values = { ...form.values }
        if (form.values.type === QuestionTypes.MULTIPLE_CHOICE) {
          if (prev.type === QuestionTypes.MULTIPLE_CHOICE) {
            return values.questions[index]
          }
          const { answer, ...rest } = values.questions[index]
          return {
            ...rest,
            type: QuestionTypes.MULTIPLE_CHOICE,
            answers: [{ id: Date.now(), correct: true }],
          }
        } else if (form.values.type === QuestionTypes.IMAGE_CHOICE) {
          const { answer, ...rest } = values.questions[index]

          return {
            ...rest,
            type: QuestionTypes.IMAGE_CHOICE,
            answers: [{ id: Date.now(), correct: true }],
          }
        } else if (form.values.type === QuestionTypes.SHORT_ANSWER) {
          const { answer, answers, ...rest } = values.questions[index]
          return {
            ...rest,
            type: QuestionTypes.SHORT_ANSWER,
          }
        } else {
          const { answers, ...rest } = values.questions[index]
          return {
            ...rest,
            type: QuestionTypes.TRUE_FALSE,
            answer: { correct: true },
          }
        }
      })

      form.setFieldValue('questions', questions)
      setPrevType(form.values.type)
    }
  }, [form.values.type, prevType, initialValues])

  useEffect(() => {
    if (initialValues) {
      testFunction()
    }
  }, [form.values.type])

  useEffect(() => {
    if (!initialValues) {
      form.values.questions.forEach((_, index) => {
        if (form.values.type === QuestionTypes.MULTIPLE_CHOICE) {
          form.setFieldValue(`questions[${index}].answers`, [
            { id: Date.now(), answer: '', correct: true },
          ])
        } else if (form.values.type === QuestionTypes.IMAGE_CHOICE) {
          form.setFieldValue(`questions[${index}].answers`, [
            { id: Date.now(), correct: true },
          ])
        } else {
          form.setFieldValue(`questions[${index}].answer`, { correct: true })
        }
      })
    }
  }, [form.values.type, initialValues])

  useEffect(() => {
    if (form.submitCount > 0) {
      ;(form?.values?.questions || []).forEach((_, questionIndex) => {
        ;(form?.values?.questions[questionIndex]?.answers || []).forEach(
          (_, answerIndex) => {
            form.setFieldTouched(
              `questions[${questionIndex}].answers[${answerIndex}].${
                form.values.type === QuestionTypes.MULTIPLE_CHOICE
                  ? 'answer'
                  : 'attachmentId'
              }`,
              true,
              true,
            )
          },
        )
      })
    }
  }, [form.submitCount, form.values.type])

  return (
    <Row className='w-100 mt-3'>
      {!disableTypeSelection && (
        <Col sm='12' className=' mb-2'>
          <Label
            className='form-check-label mb-2'
            chekc
            htmlFor='flexSwitchCheckDefault'
          >
            Question Type*
          </Label>
          <Select
            className='w-100'
            value={options.find(o => o.value === form.values.type)}
            onChange={value => {
              form.setFieldValue('type', value?.value)
            }}
            options={options.filter(o => allowedTypes.includes(o.value))}
            name='choices-single-default'
          />
        </Col>
      )}
      {languages.length !== 1 && (
        <Col sm='12' className='d-flex'>
          <div style={{ flex: 1 }}>
            <Nav
              tabs
              className='nav w-100 justify-content-start m-2  mx-0 nav-tabs-custom rounded card-header-tabs border-bottom-1'
            >
              {languages.map((l, i) => (
                <NavItem key={l.name}>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      color:
                        get(form, `errors.questions[${i}]`) &&
                        get(form, `touched.questions[${i}]`)
                          ? '#F25959'
                          : '#0039C7',
                      paddingBottom: 10,
                    }}
                    className={classnames({
                      active: l.id === activeLanguage,
                    })}
                    onClick={() => {
                      setActiveLanguage(l.id)
                    }}
                  >
                    <div className='form-check p-0'>
                      <Label
                        className='form-check-label'
                        chekc
                        htmlFor='flexSwitchCheckDefault'
                      >
                        {l.name}
                      </Label>
                    </div>
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </div>
        </Col>
      )}
      <Col sm='12'>
        <TabContent activeTab={activeLanguage || 0}>
          {form.values.questions.map((l, index) => (
            <TabPane tabId={l.languageId} key={index}>
              <div className='vstack gap-4 mt-3'>
                <div>
                  <label htmlFor='groupName' className='form-label'>
                    Question*
                  </label>
                  <Input
                    className='form-control'
                    id='question'
                    placeholder='Enter name'
                    type='textarea'
                    rows='6'
                    invalid={
                      (form.errors?.questions as any)?.[index]?.question &&
                      (form.touched?.questions as any)?.[index]?.question
                    }
                    value={(form.values['questions'][index] as any).question}
                    onChange={form.handleChange(`questions[${index}].question`)}
                    onBlur={form.handleBlur(`questions[${index}].question`)}
                  />
                  {(form.errors?.questions as any)?.[index]?.question &&
                  (form.touched?.questions as any)?.[index]?.question ? (
                    <FormFeedback type='invalid'>
                      {(form.errors?.questions as any)?.[index]?.question}
                    </FormFeedback>
                  ) : null}
                </div>
                <FormikProvider value={form}>
                  <FieldArray
                    name={`questions[${index}].answers`}
                    render={({ push, remove }) => (
                      <>
                        {form.values.type === QuestionTypes.TRUE_FALSE && (
                          <BoolAnswer
                            onChange={correct => {
                              form.setFieldValue(`questions[${index}].answer`, {
                                correct,
                              })
                            }}
                            index={index}
                            value={
                              !!form.values.questions[index]?.answer?.correct
                            }
                          />
                        )}
                        {form.values.type === QuestionTypes.MULTIPLE_CHOICE && (
                          <TextAnswers
                            errors={getAnswerErrors(index)}
                            touched={getTouchedAnswers(index)}
                            index={index}
                            maxMultiChoiceQuestions={
                              maxMultiChoiceQuestions ||
                              defaultMaxMultiChoiceQuestions
                            }
                            hideCorrectAnswer={hideCorrectAnswer}
                            onReOrder={result => onReOrder(result, index)}
                            handleAnswerChange={(
                              answerIndex: number,
                              value: string | boolean,
                            ) => {
                              onTextAnswerChange(index, answerIndex, value)
                            }}
                            handleCorrectChange={correctIndex => {
                              ;(
                                form.values.questions[index].answers || []
                              ).forEach((_, i: number) => {
                                form.setFieldValue(
                                  `questions[${index}].answers[${i}].correct`,
                                  i === correctIndex,
                                )
                              })
                            }}
                            handleBlur={form.handleBlur}
                            onDelete={i => {
                              remove(i)
                            }}
                            answers={
                              (form.values?.questions[index]?.answers ||
                                []) as TextAnswer[]
                            }
                          />
                        )}
                        {form.values.type === QuestionTypes.IMAGE_CHOICE && (
                          <ImageAnswers
                            errors={getAnswerErrors(index)}
                            touched={getTouchedAnswers(index)}
                            index={index}
                            onReOrder={result => onReOrder(result, index)}
                            handleAnswerChange={(
                              answerIndex: number,
                              attachment: TAttachment,
                            ) => {
                              onImageAnswerChange(
                                index,
                                answerIndex,
                                attachment,
                              )
                            }}
                            handleCorrectChange={correctIndex => {
                              ;(
                                form.values.questions[index]?.answers || []
                              ).forEach((_, i: number) => {
                                form.setFieldValue(
                                  `questions[${index}].answers[${i}].correct`,
                                  i === correctIndex,
                                )
                              })
                            }}
                            handleBlur={form.handleBlur}
                            onDelete={i => {
                              remove(i)
                            }}
                            answers={
                              (form.values?.questions[index]?.answers ||
                                []) as ImageAnswer[]
                            }
                          />
                        )}
                        {form.values?.questions[index]?.answers?.length !==
                          (maxMultiChoiceQuestions ||
                            defaultMaxMultiChoiceQuestions) &&
                          ![
                            QuestionTypes.TRUE_FALSE,
                            QuestionTypes.SHORT_ANSWER,
                          ].includes(form.values.type) && (
                            <Button
                              style={{ width: 80 }}
                              color='light'
                              className='btn-label text-muted'
                              size='sm'
                              onClick={() => {
                                push(
                                  form.values.type ===
                                    QuestionTypes.IMAGE_CHOICE
                                    ? { id: Date.now(), correct: false }
                                    : {
                                        id: Date.now(),
                                        answer: '',
                                        correct: false,
                                      },
                                )
                              }}
                            >
                              <i className='ri-add-line me-1'></i>
                              Add
                            </Button>
                          )}
                      </>
                    )}
                  />
                </FormikProvider>
              </div>
            </TabPane>
          ))}
        </TabContent>
        <Row className='text-end'>
          <Col sm='6'></Col>
          {!hideBottomAtions && (
            <Col sm='6'>
              <div className='d-flex gap-1 justify-content-end'>
                <Button
                  color='ghost-primary'
                  className='text-light-purple'
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  color='ghost-primary'
                  className='text-light-purple'
                  onClick={() => form.submitForm()}
                >
                  Save Question
                </Button>
              </div>
            </Col>
          )}
        </Row>
      </Col>

      <Col></Col>
    </Row>
  )
}
