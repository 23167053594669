import React, { useEffect, useRef, useState } from 'react'
import { Col } from 'reactstrap'
import _ from 'lodash'

import {
  CoursesTabs,
  CourseTypesOptions,
  FilterOption,
  ONLINE_COURSE_STATUS,
  SettingsPermissions,
  StatesOptions,
} from '../../../sharedTypes'
import MultiSelect from '../../../Components/Common/MultiSelect'
import { FiltersProps } from './types'
import { CoursePackageStatus } from '../../../sharedTypes/models/coursePackage'
import Flatpickr from 'react-flatpickr'
import { AsyncSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import { FTagSortBy, GetFTagsDTO } from '../../../sharedTypes/api/ftags'
import { getFTags } from '../../../helpers/api_helper'

const Filters: <FilterObject>(
  props: FiltersProps<FilterObject>,
) => React.JSX.Element | null = ({ visible, setFilters, activeTab }) => {
  if (!visible) {
    return null
  }

  const datePickerRef = useRef<Flatpickr>(null)
  const [selectedStatus, setSelectedStatus] = useState<FilterOption[]>([])

  useEffect(() => {
    setSelectedStatus([])
  }, [activeTab])

  const fetchFTags = (inputValue?: string) => {
    const params: GetFTagsDTO.Request = {
      page: 1,
      limit: 20,
      key: inputValue,
      permission: SettingsPermissions.VIEW_FTAG_LISTING,
      sortBy: FTagSortBy.CODE,
      withCourses: true,
    }

    return getFTags(params)
      .then(res => {
        return res.ftags.map(ftag => ({
          value: ftag.id,
          label: ftag.code + ' - ' + ftag.name,
        }))
      })
      .catch(() => [])
  }

  return (
    <>
      {activeTab === CoursesTabs.COURSES ? (
        <>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='courseStatus' className='form-label'>
                Filter by Status
              </label>

              <MultiSelect
                name='status'
                id='courseStatus'
                isMulti
                options={Object.values(ONLINE_COURSE_STATUS).map(value => ({
                  value: value,
                  label: _.capitalize(value),
                }))}
                isSearchable={true}
                placeholder='Select status'
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={selectedStatus}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    statuses: _.isEmpty(option) ? [] : _.map(option, 'value'),
                  }))
                  setSelectedStatus(option)
                }}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='ftag' className='form-label'>
                Filter by FTag
              </label>

              <AsyncSelectWithSearch
                name='ftag'
                id='ftag'
                isMulti={false}
                isClearable={true}
                isSearchable={true}
                placeholder='Select FTag'
                defaultOptions
                onChange={(option: FilterOption) => {
                  setFilters(prev => ({
                    ...prev,
                    ftags: option ? option.value : undefined,
                  }))
                }}
                loadOptions={fetchFTags}
                styles={{
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  option: base => ({ ...base, cursor: 'pointer' }),
                }}
                menuPortalTarget={document.body}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='type' className='form-label'>
                Filter by Type
              </label>

              <MultiSelect
                name='type'
                id='type'
                isMulti
                options={CourseTypesOptions}
                isSearchable={true}
                placeholder='Select type'
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    types: _.isEmpty(option) ? [] : _.map(option, 'value'),
                  }))
                }}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0'>
            <div>
              <label htmlFor='validStates' className='form-label'>
                Filter by Valid States
              </label>

              <MultiSelect
                name='validState'
                id='type'
                isMulti
                options={StatesOptions}
                isSearchable={true}
                placeholder='Select valid state'
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    states: _.isEmpty(option) ? [] : _.map(option, 'value'),
                  }))
                }}
              />
            </div>
          </Col>
        </>
      ) : (
        <>
          <Col className='mt-0 p-0' sm={3}>
            <div>
              <label htmlFor='packageStatus' className='form-label'>
                Filter by Status
              </label>

              <MultiSelect
                name='packageStatus'
                id='packageStatus'
                isMulti
                options={Object.values(CoursePackageStatus).map(value => ({
                  value: value,
                  label: _.capitalize(value),
                }))}
                isSearchable={true}
                placeholder='Select status'
                isClearable={false}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                value={selectedStatus}
                onChange={(option: FilterOption[]) => {
                  setFilters(prev => ({
                    ...prev,
                    statuses: _.isEmpty(option) ? [] : _.map(option, 'value'),
                  }))
                  setSelectedStatus(option)
                }}
              />
            </div>
          </Col>
          <Col className='mt-0 p-0' sm={3}>
            <div className='hstack gap-3 px-3 mx-n3 justify-content-between'>
              <div className='w-100 form-icon right filter-by-date'>
                <label htmlFor='type' className='form-label'>
                  Filter by Publish Date
                </label>

                <Flatpickr
                  className='form-control'
                  placeholder='--/--/---- - --/--/---'
                  onChange={dates => {
                    if (dates.length === 2) {
                      setFilters(prev => ({
                        ...prev,
                        publishedDate: dates,
                      }))
                    }
                  }}
                  ref={datePickerRef}
                  options={{
                    mode: 'range',
                    dateFormat: 'd-m-Y',
                    locale: {
                      rangeSeparator: ' - ',
                    },
                  }}
                />
                <i
                  className='ri-close-line fs-16 text-danger'
                  onClick={() => {
                    datePickerRef.current?.flatpickr.clear()
                    setFilters(prev => ({
                      ...prev,
                      publishedDate: [],
                    }))
                  }}
                ></i>
                <i
                  className='ri-calendar-2-line fs-20 text-primary'
                  onClick={() => {
                    datePickerRef.current?.flatpickr.open()
                  }}
                ></i>
              </div>
            </div>
          </Col>
        </>
      )}
    </>
  )
}

export default Filters
