import React, { useEffect, useState, useCallback, useMemo } from 'react'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import {
  Card,
  Col,
  Form,
  Container,
  Input,
  Label,
  Row,
  CardBody,
  FormFeedback,
  CardHeader,
} from 'reactstrap'
import { useFormik } from 'formik'
import Slider from 'react-rangeslider'
import {
  getDefaultCourseSettings,
  putDefaultCourseSettings,
  getCertificateTemplates,
  getAdmins,
  getAllLanguages,
  getAdminCourseNotificationSettings,
  updateAdminCourseNotificationSettings,
  uploadFacilityVideo,
  getFacilitySettings,
  deleteFacilityVideo,
} from '../../../helpers/api_helper'
import {
  IDefaultCourseSettings,
  ICerticateTemplate,
  SettingsPermissions,
  AttachmentsMimeTypeEnum,
  DocumentFile,
  TAttachment,
} from '../../../sharedTypes'
import { toast } from 'react-toastify'
import { SignatureUpload } from '../../../Components/Common/SignatureUpload'
import { handleError, successToastOptions } from '../../../helpers/toast_helper'
import { Button } from 'reactstrap'
import handleAcceptedFile from '../../../utils/handleAcceptedFile'
import * as Yup from 'yup'
import { usePermissions } from '../../../hooks/usePermissions'
import { AsyncSelectWithSearch } from '../../../Components/Common/SelectWithSearch'
import { UserManagementPermissions } from '../../../sharedTypes'
import { getUserDisplayName } from '../../../helpers/user'
import withRouter, {
  WithRouterProps,
} from '../../../Components/Common/withRouter'
import { ENGLISH_LANGUAGE_CODE } from '../../../helpers/common'
import { PutAdminCourseSettingsDTO } from '../../../sharedTypes/api/adminCourseNotificationSettings'
import useIsMasteredAdmin from '../../../hooks/useIsMasteredAdmin'
import Dropzone from 'react-dropzone'
import formatBytes from '../../../utils/formatBytes'
import { useAppSelector } from '../../../hooks/redux'
import _ from 'lodash'
import { Link } from 'react-router-dom'

interface Option {
  value: number
  label: string | number
}

interface IForm extends Omit<IDefaultCourseSettings, 'signatoryId'> {
  signatoryId: null | Option | undefined
}

export enum FILTER_PARAM {
  DIGITAL_COURSE_REPLACED = 'digitalCourseReplaced',
  COURSE_EXPIRING_WITH_NO_REVISION = 'courseExpiringWithNoRevision',
}

const initialValues = {
  completeDays: 0,
  quizQuestions: 0,
  quizAttempts: 100,
  passingScore: 100,
  watchPercentage: 100,
  enforceOrder: true,
  attestationStatement: true,
  attestationStatementText: '',
  certificateId: 7,
  signature: undefined,
  signatoryPosition: '',
  signatoryId: undefined,
  signatory: undefined,
  messages: {
    success: '',
    retake: '',
    fail: '',
  },
  languages: [],
}
const DefaultCourseSettings = ({ router }: WithRouterProps) => {
  document.title = 'Default Settings | Mastered - Admin & Dashboard'

  const { user } = useAppSelector(state => ({
    user: state.User.user,
  }))

  const [templates, setTemplates] = useState<ICerticateTemplate[]>([])
  const [isLoading, setLoading] = useState(false)
  const [digitalCourseAdmins, setDigitalCourseAdmins] =
    useState<PutAdminCourseSettingsDTO.Response>([])

  const hasPermissionToSave = usePermissions(
    SettingsPermissions.EDIT_DEFAULT_COURSE_SETTINGS,
  )
  const [file, setFile] = useState<DocumentFile | TAttachment | null>()

  useEffect(() => {
    getFacilitySettings().then(res => setFile(res.data.defaultVideo))
  }, [])

  const onDrop = useCallback((files: any) => {
    const parsedFiles = files.map((file: File) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    )
    setFile(parsedFiles[0])
  }, [])

  const onUpload = useCallback(async () => {
    try {
      setLoading(true)
      if (file) {
        await uploadFacilityVideo({
          file,
        })
        toast('File uploaded successfully.', successToastOptions)
      }
    } catch (e) {
    } finally {
      setLoading(false)
    }
  }, [file])

  const onDelete = async () => {
    try {
      setFile(null)
      if ((file as TAttachment).id) {
        await deleteFacilityVideo((file as TAttachment).id)
        toast('File deleted successfully.', successToastOptions)
      }
    } catch (e) {
      handleError(e)
    }
  }

  const handleAdminNotificationChange = (
    option: { label: string; value: string }[],
    digitalCourseAdmins: PutAdminCourseSettingsDTO.Response,
    filterParameter: FILTER_PARAM,
  ) => {
    const toDelete = digitalCourseAdmins
      .filter(item => item[filterParameter])
      .map(item => {
        const userId = item.user?.id

        const isNotSelected = !option
          .map((opt: { value: string }) => +opt.value)
          .includes(userId)

        if (isNotSelected) {
          return {
            [filterParameter]: false,
            userId: userId || null,
          }
        } else {
          return {}
        }
      })

    const toAdd = option.map((item: { value: string }) => ({
      [filterParameter]: true,
      userId: +item.value,
    }))

    return [...toDelete, ...toAdd].filter(item => item.userId)
  }

  const isMasteredAdmin = useIsMasteredAdmin()

  const fetchAdmins = (inputValue?: string): Promise<Option[]> => {
    const params = {
      page: 1,
      limit: 10,
      key: inputValue,
    }

    return getAdmins({
      ...params,
      permission: UserManagementPermissions.VIEW_ADMIN_LISTING,
    })
      .then(d => d.data)
      .then(({ admins }) => {
        const options = admins.map(admin => ({
          value: admin.id,
          label: getUserDisplayName(admin),
        }))
        return options
      })
      .catch(() => [])
  }

  const form = useFormik<IForm>({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values: IForm, formik) => {
      try {
        const form = new FormData()
        form.append('completeDays', String(values.completeDays))
        form.append('quizQuestions', String(values.quizQuestions))
        form.append('quizAttempts', String(values.quizAttempts))
        form.append('passingScore', String(values.passingScore))
        form.append('watchPercentage', String(values.watchPercentage))
        form.append('enforceOrder', String(values.enforceOrder))
        form.append('signatoryPosition', String(values.signatoryPosition))
        form.append('attestationStatement', String(values.attestationStatement))
        form.append(
          'attestationStatementText',
          String(values.attestationStatementText),
        )
        form.append('certificateId', String(values.certificateId))
        form.append('messages', JSON.stringify(values.messages))

        if (values.signatoryId) {
          form.append('signatoryId', String((values.signatoryId as any).value))
        }
        if (values.signature) {
          form.append('signature', values.signature as Blob)
        }
        if (values.certificateId) {
          form.append('certificateId', String(values.certificateId))
        }
        form.append('languages', JSON.stringify(values.languages))

        if (values.digitalCoursesChanged) {
          const courseChanged = handleAdminNotificationChange(
            values.digitalCoursesChanged,
            digitalCourseAdmins,
            FILTER_PARAM.DIGITAL_COURSE_REPLACED,
          )
          updateAdminCourseNotificationSettings(
            courseChanged as PutAdminCourseSettingsDTO.Request,
          )
        }
        if (values.coursesExpiring) {
          const courseExpiring = handleAdminNotificationChange(
            values.coursesExpiring,
            digitalCourseAdmins,
            FILTER_PARAM.COURSE_EXPIRING_WITH_NO_REVISION,
          )
          updateAdminCourseNotificationSettings(
            courseExpiring as PutAdminCourseSettingsDTO.Request,
          )
        }

        const response = await putDefaultCourseSettings(form)

        updateFormValues({
          ...response,
          languages: values.languages,
          digitalCoursesChanged: values.digitalCoursesChanged,
          coursesExpiring: values.coursesExpiring,
        })

        toast('Successfully updated', successToastOptions)
      } catch (e) {}
    },
    validationSchema: Yup.object({
      signature: Yup.mixed()
        .nullable()
        .required('Required')
        .test(
          'valid-size',
          'Max allowed size is 1mb',
          value => value?.size <= 1000000,
        ),
      signatoryPosition: Yup.string().nullable().required('Required'),
      completeDays: Yup.number().min(1).nullable().required('Required'),
      quizQuestions: Yup.number().min(1).nullable().required('Required'),
      quizAttempts: Yup.number().min(1).nullable().required('Required'),
      passingScore: Yup.number().min(0).nullable().required('Required'),
      watchPercentage: Yup.number().min(1).nullable().required('Required'),
      signatoryId: Yup.mixed().required('Required'),
    }),
  })

  useEffect(() => {
    setLoading(true)
    form.setFieldValue(
      'coursesExpiring',
      digitalCourseAdmins
        .filter(item => item.courseExpiringWithNoRevision)
        .map(item => ({
          value: item.userId,
          label: getUserDisplayName(
            digitalCourseAdmins.find(admin => admin.userId === item.userId)
              ?.user,
          ),
        })),
    )
    form.setFieldValue(
      'digitalCoursesChanged',
      digitalCourseAdmins
        .filter(item => item.digitalCourseReplaced)
        .map(item => ({
          value: item.userId,
          label: getUserDisplayName(
            digitalCourseAdmins.find(admin => admin.userId === item.userId)
              ?.user,
          ),
        })),
    )
    setLoading(false)
  }, [digitalCourseAdmins])

  const updateFormValues = useCallback((settings: IDefaultCourseSettings) => {
    form.setValues({
      ...settings,
      signatoryId: settings.signatory
        ? {
            label: getUserDisplayName(settings.signatory),
            value: settings.signatory.id,
          }
        : null,
    })
  }, [])

  useEffect(() => {
    setLoading(true)
    getAdminCourseNotificationSettings().then(data =>
      setDigitalCourseAdmins(data),
    )
    Promise.all([
      getDefaultCourseSettings({
        permission: SettingsPermissions.VIEW_DEFAULT_COURSE_SETTINGS,
      }),
      getCertificateTemplates(),
      getAllLanguages(),
    ])
      .then(([settings, templates, languages]) => {
        updateFormValues({ ...settings, languages })
        setTemplates(templates)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb
            title='DEFAULT SETTINGS'
            items={[
              {
                title: 'Settings',
                linkTo: '/',
              },
              {
                title: 'Default Settings',
                active: true,
              },
            ]}
          />
          {isLoading ? (
            <Card className='m-0 h-100' style={{ display: 'block' }}>
              <CardBody>
                <div className='d-flex flex-column align-items-center py-5'>
                  <p style={{ fontSize: 20, fontWeight: 600 }}>Loading...</p>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Form
              onSubmit={e => {
                e.preventDefault()
                form.handleSubmit()
                return false
              }}
              action='#'
            >
              <Row className='gy-4 mb-3'>
                <Col
                  xxl={isMasteredAdmin ? 12 : 6}
                  md={isMasteredAdmin ? 12 : 6}
                >
                  <Card className='p-4'>
                    <CardHeader
                      className='border-bottom-0 p-0 pb-2 fs-20'
                      style={{ fontWeight: 700 }}
                    >
                      Course Settings
                    </CardHeader>
                    <Row className='gy-4 mb-3'>
                      <Col xxl={6} md={6} className='d-flex align-items-start'>
                        <div className='w-100'>
                          <Label htmlFor='basiInput' className='form-label'>
                            Number of days to complete course
                          </Label>
                          <Input
                            type='number'
                            className='form-control'
                            name='completeDays'
                            value={form.values.completeDays}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            id='basiInput'
                            invalid={
                              !!(
                                form.touched.completeDays &&
                                form.errors.completeDays
                              )
                            }
                          />
                          {form.touched.completeDays &&
                          form.errors.completeDays ? (
                            <FormFeedback type='invalid'>
                              {form.errors.completeDays}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={6} md={6} className='d-flex align-items-start'>
                        <div className='w-100'>
                          <Label htmlFor='basiInput' className='form-label'>
                            Number of Test Questions
                          </Label>
                          <Input
                            type='number'
                            className='form-control'
                            name='quizQuestions'
                            value={form.values.quizQuestions}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            id='basiInput'
                            invalid={
                              !!(
                                form.touched.quizQuestions &&
                                form.errors.quizQuestions
                              )
                            }
                          />
                          {form.touched.quizQuestions &&
                          form.errors.quizQuestions ? (
                            <FormFeedback type='invalid'>
                              {form.errors.quizQuestions}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                      <Col xxl={6} md={6} className='d-flex align-items-start'>
                        <div className='w-100'>
                          <Label htmlFor='basiInput' className='form-label'>
                            Test attempts before having to restart the course{' '}
                          </Label>
                          <Input
                            type='number'
                            className='form-control'
                            name='quizAttempts'
                            value={form.values.quizAttempts}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            id='basiInput'
                            invalid={
                              !!(
                                form.touched.quizAttempts &&
                                form.errors.quizAttempts
                              )
                            }
                          />
                          {form.touched.quizAttempts &&
                          form.errors.quizAttempts ? (
                            <FormFeedback type='invalid'>
                              {form.errors.quizAttempts}
                            </FormFeedback>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row className='gy-4 mb-3'>
                      <Col xxl='6'>
                        <label htmlFor='bio' className='form-label mb-4'>
                          Required watch percentage
                        </label>
                        <Slider
                          value={form.values.watchPercentage}
                          tooltip={false}
                          orientation='horizontal'
                          handleLabel={String(form.values.watchPercentage)}
                          onChange={(value: number) => {
                            form.setFieldValue('watchPercentage', value)
                          }}
                        ></Slider>
                      </Col>
                      <Col xxl='6'>
                        <label htmlFor='bio' className='form-label mb-4'>
                          Passing scores for each course added
                        </label>
                        <Slider
                          value={form.values.passingScore}
                          tooltip={false}
                          orientation='horizontal'
                          handleLabel={String(form.values.passingScore)}
                          onChange={(value: number) => {
                            form.setFieldValue('passingScore', value)
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className='gy-4 mb-3'>
                      <Col xxl='12'>
                        <div>
                          <label htmlFor='bio' className='form-label'>
                            Enforce watch order
                          </label>
                          <div className='form-check mb-2'>
                            <Input
                              className='form-check-input'
                              type='radio'
                              name='enforceOrder'
                              checked={form.values.enforceOrder}
                              onChange={() => {
                                form.setFieldValue('enforceOrder', true)
                              }}
                              id='flexRadioDefault1'
                            />
                            <Label
                              className='form-check-label'
                              htmlFor='flexRadioDefault1'
                            >
                              Yes
                            </Label>
                          </div>
                          <div className='form-check'>
                            <Input
                              className='form-check-input'
                              type='radio'
                              name='enforceOrder'
                              checked={!form.values.enforceOrder}
                              onChange={() => {
                                form.setFieldValue('enforceOrder', false)
                              }}
                              defaultChecked
                            />
                            <Label
                              className='form-check-label'
                              htmlFor='flexRadioDefault2'
                            >
                              No
                            </Label>
                          </div>
                        </div>
                      </Col>

                      <Col sm='12'>
                        <Row className='w-100 pt-3'>
                          <Col sm='6'>
                            <div>
                              <label htmlFor='title' className='form-label'>
                                Test Success Message
                              </label>
                              <Input
                                className='form-control'
                                id='title'
                                name={`messages.success`}
                                placeholder='Enter message'
                                type='textarea'
                                rows='3'
                                value={form.values?.messages?.success}
                                onChange={e => {
                                  form.setFieldValue(
                                    'messages.success',
                                    e.target.value,
                                  )
                                }}
                                onBlur={() =>
                                  form.setFieldTouched(`messages.success`)
                                }
                              />
                            </div>
                          </Col>

                          <Col sm='6'>
                            <div>
                              <label htmlFor='title' className='form-label'>
                                Test Fail Message
                              </label>
                              <Input
                                className='form-control'
                                id='title'
                                name={`messages.fail`}
                                placeholder='Enter message'
                                type='textarea'
                                rows='3'
                                value={form.values?.messages?.fail}
                                onChange={e => {
                                  form.setFieldValue(
                                    `messages.fail`,
                                    e.target.value,
                                  )
                                }}
                                onBlur={() =>
                                  form.setFieldTouched(`messages.fail`)
                                }
                              />
                            </div>
                          </Col>

                          <Col sm='12' className='mt-3'>
                            <div>
                              <label htmlFor='title' className='form-label'>
                                Test Retake Message
                              </label>
                              <Input
                                className='form-control'
                                id='title'
                                name={`messages.retake`}
                                placeholder='Enter message'
                                type='textarea'
                                rows='3'
                                value={form.values?.messages?.retake}
                                onChange={e => {
                                  form.setFieldValue(
                                    `messages.retake`,
                                    e.target.value,
                                  )
                                }}
                                onBlur={() =>
                                  form.setFieldTouched(`messages.retake`)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                  {isMasteredAdmin && (
                    <Card className='p-4'>
                      <CardHeader className='border-bottom-0 p-0 pb-4 fs-20 fw-bold'>
                        Language Settings
                      </CardHeader>
                      <Row className='gy-4 mb-3'>
                        {form.values.languages.map((language, i) => (
                          <Col xxl={12} key={language.id}>
                            <div className='form-check form-switch mb-2'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                role='switch'
                                disabled={
                                  language.code === ENGLISH_LANGUAGE_CODE
                                }
                                id={`switch${language.id}`}
                                checked={language.active}
                                onChange={e => {
                                  form.setFieldValue(
                                    `languages[${i}].active`,
                                    e.target.checked,
                                  )
                                }}
                              />
                              <Label
                                className='form-check-label'
                                chekc
                                htmlFor={`switch${language.id}`}
                              >
                                {language.name}
                              </Label>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Card>
                  )}
                  <Card className='p-4'>
                    <CardHeader className='border-bottom-0 p-0 pb-4 fs-20 fw-bold'>
                      Facility Landing Page
                    </CardHeader>
                    <Row className='gy-4 mb-3'>
                      <div className='cursor-pointer'>
                        <Dropzone
                          maxFiles={1}
                          accept={{ 'video/mp4': [] }}
                          disabled={isLoading}
                          onDrop={onDrop}
                        >
                          {({ getRootProps }) => (
                            <div className='dropzone dz-clickable'>
                              <div
                                className='dz-message needsclick'
                                {...getRootProps()}
                              >
                                <div className='mt-2'>
                                  <i className='display-7 text-muted ri-upload-cloud-2-fill' />
                                </div>
                                <p className='m-0 text-muted fs-16'>
                                  Drop files here or click to upload.
                                  <br></br>
                                  {[AttachmentsMimeTypeEnum.VIDEO_MP4]
                                    ?.map(t => t.split('/')[1])
                                    .join(',')}
                                </p>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        {file && (
                          <div className='p-2'>
                            <Row
                              className='align-items-center py-2'
                              style={{
                                border: '1px solid #E9EBEC',
                                borderRadius: '4px',
                              }}
                            >
                              <Col className='col-auto'>
                                <div className='d-flex align-items-center justify-content-center'>
                                  <video
                                    data-dz-thumbnail=''
                                    height='80'
                                    autoPlay={false}
                                    controls={false}
                                    className='avatar-sm rounded bg-light'
                                    src={
                                      (file as DocumentFile).preview ||
                                      (file as TAttachment).path
                                    }
                                  />
                                </div>
                              </Col>
                              <Col>
                                <Link
                                  to={(file as TAttachment).path}
                                  className='text-muted font-weight-bold'
                                >
                                  {file.name}
                                </Link>
                                <p className='mb-0'>
                                  {formatBytes((file as TAttachment).size)}
                                </p>
                              </Col>
                            </Row>
                          </div>
                        )}
                        <div className='mt-3 d-flex justify-content-end gap-2'>
                          <Button
                            disabled={isLoading}
                            color='danger'
                            onClick={() => onDelete()}
                          >
                            {' '}
                            Delete{' '}
                          </Button>
                          <Button
                            color='success'
                            onClick={onUpload}
                            disabled={isLoading}
                          >
                            Upload
                          </Button>
                        </div>
                      </div>
                    </Row>
                  </Card>
                </Col>
                {!isMasteredAdmin && (
                  <Col xxl='6' md='6' className='d-flex align-items-start'>
                    <Row>
                      <Col sm='12'>
                        <Card className='p-4'>
                          <CardHeader
                            className='border-bottom-0 p-0 pb-2 fs-20'
                            style={{ fontWeight: 700 }}
                          >
                            Signatory
                          </CardHeader>
                          <Row className='gy-4 mb-3'>
                            <Col sm='6'>
                              <div>
                                <label className='form-label'>Signatory</label>
                                <div className='d-flex'>
                                  <div className='flex-grow-1'>
                                    <AsyncSelectWithSearch
                                      styles={{
                                        control: baseStyles => ({
                                          ...baseStyles,
                                          borderRadius: '4px 0px 0px 4px',
                                          minHeight: 39,
                                          borderColor:
                                            form.touched.signatoryId &&
                                            form.errors.signatoryId
                                              ? '#f06548 !important'
                                              : undefined,
                                        }),
                                      }}
                                      placeholder='Select instructor'
                                      className='select2-container is-invalid'
                                      name='instructorId'
                                      id='instructorId'
                                      onChange={option =>
                                        form.setFieldValue(
                                          'signatoryId',
                                          option,
                                        )
                                      }
                                      onBlur={() => {
                                        form.setFieldTouched(
                                          'signatoryId',
                                          true,
                                        )
                                      }}
                                      value={form.values.signatoryId}
                                      isMulti={false}
                                      isClearable={true}
                                      isSearchable={true}
                                      defaultOptions
                                      loadOptions={fetchAdmins}
                                      error={
                                        form.touched.signatoryId
                                          ? form.errors.signatoryId
                                          : undefined
                                      }
                                    />
                                  </div>
                                  <div></div>
                                </div>
                                {form.touched.signatoryId &&
                                form.errors.signatoryId ? (
                                  <FormFeedback type='invalid'>
                                    {form.errors.signatoryId}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm='6' className='d-flex align-items-start'>
                              <div className='w-100'>
                                <Label
                                  htmlFor='basiInput'
                                  className='form-label'
                                >
                                  Signatory position
                                </Label>
                                <Input
                                  type='text'
                                  className='form-control'
                                  name='signatoryPosition'
                                  value={form.values.signatoryPosition || ''}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                  id='basiInput'
                                  invalid={
                                    !!(
                                      form.touched.signatoryPosition &&
                                      form.errors.signatoryPosition
                                    )
                                  }
                                />
                                {form.touched.signatoryPosition &&
                                form.errors.signatoryPosition ? (
                                  <FormFeedback type='invalid'>
                                    {form.errors.signatoryPosition}
                                  </FormFeedback>
                                ) : null}
                              </div>
                            </Col>

                            <Col sm='12'>
                              <SignatureUpload
                                error={form.errors.signature}
                                signature={form.values.signature}
                                isSubmitting={form.isSubmitting}
                                onDrop={acceptedFiles => {
                                  handleAcceptedFile(
                                    acceptedFiles,
                                    'signature',
                                    form.setFieldValue,
                                  )
                                }}
                                onDelete={() => {
                                  form.setFieldValue('signature', null)
                                }}
                              />
                            </Col>
                          </Row>
                        </Card>
                      </Col>

                      <Col sm='12'>
                        <Card className='p-4'>
                          <CardHeader
                            className='border-bottom-0 p-0 pb-2 fs-20'
                            style={{ fontWeight: 700 }}
                          >
                            Attestation
                          </CardHeader>
                          <Row className='gy-4 mb-3'>
                            <Col sm='6'>
                              <div>
                                <label htmlFor='bio' className='form-label'>
                                  Require attestation statement
                                </label>
                                <div className='form-check mb-2'>
                                  <Input
                                    className='form-check-input'
                                    type='radio'
                                    name='attestationStatement'
                                    checked={form.values.attestationStatement}
                                    onChange={() => {
                                      form.setFieldValue(
                                        'attestationStatement',
                                        true,
                                      )
                                    }}
                                    id='flexRadioDefault1'
                                  />
                                  <Label
                                    className='form-check-label'
                                    htmlFor='flexRadioDefault1'
                                  >
                                    Yes
                                  </Label>
                                </div>
                                <div className='form-check'>
                                  <Input
                                    className='form-check-input'
                                    type='radio'
                                    name='attestationStatement'
                                    checked={!form.values.attestationStatement}
                                    onChange={() => {
                                      form.setFieldValue(
                                        'attestationStatement',
                                        false,
                                      )
                                    }}
                                  />
                                  <Label
                                    className='form-check-label'
                                    htmlFor='flexRadioDefault2'
                                  >
                                    No
                                  </Label>
                                </div>
                              </div>
                            </Col>
                            <Col sm='12'>
                              <div>
                                <label
                                  htmlFor='description'
                                  className='form-label'
                                >
                                  Attestation Statement Text
                                </label>
                                <Input
                                  className='form-control'
                                  id='description'
                                  name='attestationStatementText'
                                  placeholder='Enter Attestation Statement Text'
                                  type='textarea'
                                  rows='3'
                                  value={form.values.attestationStatementText}
                                  onChange={form.handleChange}
                                  onBlur={form.handleBlur}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col sm='12'>
                        <Card className='p-4' style={{ zIndex: 10 }}>
                          <CardHeader
                            className='border-bottom-0 p-0 pb-2 fs-20'
                            style={{ fontWeight: 700 }}
                          >
                            Admin Notifications
                          </CardHeader>
                          <Row className='gy-4 mb-3'>
                            <Col sm='12'>
                              <label
                                htmlFor='digitalCourseReplaced'
                                className='form-label'
                              >
                                Digital Course Replaced
                              </label>
                              <AsyncSelectWithSearch
                                name='digitalCourseReplaced'
                                id='digitalCourseReplaced'
                                onChange={option =>
                                  form.setFieldValue(
                                    'digitalCoursesChanged',
                                    option,
                                  )
                                }
                                isMulti
                                isClearable={true}
                                isSearchable={true}
                                placeholder={'Select admins'}
                                value={form.values.digitalCoursesChanged}
                                defaultOptions
                                loadOptions={fetchAdmins}
                                className='select2-container'
                                classNamePrefix='select2-selection form-select__wrap'
                              />
                            </Col>
                            <Col sm='12'>
                              <label
                                htmlFor='courseExpiring'
                                className='form-label'
                              >
                                Course Expiring with no Published Revision
                              </label>
                              <AsyncSelectWithSearch
                                name='courseExpiring'
                                onChange={option =>
                                  form.setFieldValue('coursesExpiring', option)
                                }
                                isMulti
                                isClearable={true}
                                isSearchable={true}
                                placeholder={'Select admins'}
                                value={form.values.coursesExpiring}
                                defaultOptions
                                loadOptions={fetchAdmins}
                                className='select2-container'
                                classNamePrefix='select2-selection form-select__wrap'
                              />
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                )}
                <Col className='mt-0'>
                  <Card className='py-2 px-2'>
                    <Row>
                      <Col>
                        <div className='d-flex justify-content-start sticky-row'>
                          <Button
                            color='success'
                            type='submit'
                            className='btn-soft-primary'
                            onClick={() => {
                              router.navigate('/courses')
                            }}
                            disabled={!form.dirty || form.isSubmitting}
                          >
                            Cancel
                          </Button>
                        </div>
                      </Col>
                      {hasPermissionToSave && (
                        <Col>
                          <div className='d-flex justify-content-end sticky-row'>
                            <Button
                              color='success'
                              type='submit'
                              onClick={() => form.handleSubmit}
                              disabled={!form.dirty || form.isSubmitting}
                            >
                              Save
                            </Button>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(DefaultCourseSettings)
